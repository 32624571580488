import Breadcrumb from "../components/Breadcrumb";
import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useForm } from "react-hook-form";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { setUser } from "../Store/Actions/userActions";

const AddNewService = () => {
  const { t, i18n } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);

  const [servicess, setServices] = useState([]);
  const [isImgRequired, setIsImgRequired] = useState(false);
  const [isCardRequired, setIsCardRequired] = useState(false);
  const dispatch = useDispatch();
  const serviceA = useRef();
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/services?limit=1000`, {
        headers: {
          "Content-Type": "application/json",
          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setServices(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);
  const [service, setService] = useState("");
  const [images, setImages] = useState(null);
  const [cover, setCover] = useState(null);
  const [workDescription, setWorkDescription] = useState("");
  const [showOption, setShowOption] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });
  console.log(service);
  console.log(isImgRequired);
  console.log(cover);
  console.log(images);
  const [jumperServices, setJumperServices] = useState([]);
  useEffect(() => {
    axios
      .get(
        `https://api.jumpersksa.online/jumperServices/user_services?limit=100`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
            lang: i18n.language,
            // lang: "all",
          },
        }
      )
      .then((res) => {
        console.log(res);
        setJumperServices(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [service, i18n.language]);
  const jumperServicesIds = jumperServices.map((jumperService) => {
    return jumperService.service._id;
  });

  const serviceOptions = servicess.filter((service) => {
    return service?.isActive && !jumperServicesIds.includes(service._id);
  });
  const activeServices = serviceOptions.filter((service) => {
    return service.isActive;
  });
  console.log(jumperServicesIds);
  console.log(servicess);
  console.log(jumperServices);
  console.log(serviceOptions);
  console.log(activeServices);
  const handleSubmitt = async (data) => {
    try {
      const formData = new FormData();
      formData.append("service", service);
      formData.append("description", workDescription);
      if (isImgRequired) {
        formData.append("cover", cover);
        for (let i = 0; i < images.length; i++) {
          formData.append("images", images[i]);
        }
      }

      const response = await axios.post(
        `https://api.jumpersksa.online/jumperServices?jumper=${userInfo?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      toast.success(t("Service added successfully !"));
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      console.log("Service added successfully:", response);
    } catch (error) {
      toast.error(error?.response?.data?.msg);
      console.error("Error adding service:", error);
    }
  };
  const appendImgs = (files) => {
    setImages(files);
  };

  const [skillss, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const handleSkillsChange = (selectedOptions) => {
    setSelectedSkills(selectedOptions);
  };

  const skillOptions = skillss
    ?.filter((skill) => {
      let isExist = false;
      for (const s of userInfo?.skills) {
        if (s._id === skill._id) {
          isExist = true;
          break;
        }
      }
      return skill.isActive && !isExist;
    })
    .map((skill) => ({
      value: skill._id,
      label: skill.name,
    }));

  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/skills?limit=100`, {
        headers: {
          "content-type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setSkills(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language]);

  const handleSubmitSkills = (e) => {
    console.log(selectedSkills);
    e.preventDefault();
    const skillIds = selectedSkills.map((skill) => skill.value);
    console.log(skillIds);
    const selectedSkillsOfUSer = skillIds.concat(userInfo?.skills);
    axios
      .put(
        `https://api.jumpersksa.online/users/update_profile`,
        {
          skills: selectedSkillsOfUSer,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log("skills added successfully:");
        console.log(res);
        dispatch(setUser(res?.data?.data));
        toast.success(t("skills added successfully !"));
        // setTimeout(() => {
        //   window.location.reload();
        // }, 3000);
      })
      .catch((err) => {
        console.error(err);
      });
  };
  return (
    <>
      <Breadcrumb pageName={t("Services and Skills")} />

      <div className="mx-auto max-w-270 my-4">
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-bold text-xl text-center text-black dark:text-white">
                  {t("Add New Skill")}
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSubmitSkills}>
                  <div className="mb-5.5">
                    <div>
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Select skill name")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          name="skills"
                          value={selectedSkills}
                          onChange={handleSkillsChange}
                          options={skillOptions}
                          className="w-full"
                          classNamePrefix="select"
                          required
                        />
                      </div>
                    </div>
                  </div>

                  <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                    >
                      {t("Save Skills")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mx-auto max-w-270">
        {userInfo?.skills.length > 0 ? (
          <>
            <div className="grid grid-cols-3 gap-8">
              {userInfo?.age &&
              userInfo.gender &&
              userInfo.city &&
              userInfo.nationality ? (
                <div className="col-span-5 xl:col-span-3">
                  <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
                    <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                      <h3 className="font-bold text-xl text-center text-black dark:text-white">
                        {t("Add New Service")}
                      </h3>
                    </div>
                    {jumperServices.length > 0 && (
                      <>
                        <div>
                          <h1 className="flex justify-start font-medium text-md gap-2 m-1">
                            {t("Your Services")} :
                          </h1>
                          <div className="flex justify-evenly p-3 flex-wrap">
                            {jumperServices.map((jumperService) => {
                              return (
                                <div className="bg-white p-2 leading-none flex justify-evenly items-center dark:border-strokedark dark:bg-[#37404F] text-black dark:text-white rounded-md border border-stroke py-2.5 shadow-lg ">
                                  {jumperService.service.name}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </>
                    )}

                    <div className="p-7">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          handleSubmitt();
                        }}
                      >
                        <div className="mb-5.5">
                          <div>
                            <label
                              className="mb-3 block text-black dark:text-white"
                              htmlFor="service"
                            >
                              {t("Select Service Name")}
                            </label>
                            <div className="relative z-20 bg-white dark:bg-form-input">
                              <select
                                id="service"
                                name="service"
                                onChange={(e) => {
                                  setService(e.target.value);
                                  console.log(e.target.value);
                                  const s = servicess.find((required) => {
                                    return required._id === e.target.value;
                                  });
                                  console.log(s);
                                  setIsImgRequired(s?.isImgRequired);
                                  setIsCardRequired(s?.isCardRequired);
                                }}
                                required
                                className="relative flex z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                              >
                                <option
                                  disabled
                                  selected
                                  value=""
                                  className="w-full block"
                                >
                                  {t("Select Service")}
                                </option>
                                {serviceOptions?.map((service) => (
                                  <option
                                    key={service._id}
                                    value={service._id}
                                    className="w-full block"
                                  >
                                    {service?.name}
                                  </option>
                                ))}
                              </select>
                              <span
                                className={
                                  i18n.language === "ar"
                                    ? `absolute left-4 top-1/2 -translate-y-1/2 fill-current`
                                    : `absolute right-4 top-1/2 -translate-y-1/2 fill-current `
                                }
                              >
                                <svg
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <g opacity="0.8">
                                    <path
                                      fillRule="evenodd"
                                      clipRule="evenodd"
                                      d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                      fill="#637381"
                                    ></path>
                                  </g>
                                </svg>
                              </span>
                            </div>
                          </div>
                        </div>
                        {isCardRequired && (
                          <>
                            <div className="mb-5.5">
                              <label
                                className="mb-3 block text-black dark:text-white"
                                htmlFor="image"
                              >
                                {t("Govenrment Card")}
                              </label>
                              <input
                                onChange={(e) => {
                                  setCover(e.target.files[0]);
                                }}
                                type="file"
                                id="image"
                                name="cover"
                                required
                                className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
                              />
                            </div>
                          </>
                        )}
                        {isImgRequired && (
                          <>
                            <h2>{t("image is required in this service")}</h2>

                            <div className="mb-5.5">
                              {/* <label
                                className="mb-3 block text-black dark:text-white"
                                htmlFor="image"
                              >
                                {t("images")}
                              </label> */}
                              <input
                                multiple
                                onChange={(e) => {
                                  appendImgs(e.target.files);
                                }}
                                type="file"
                                id="image"
                                name="images"
                                required
                                className="w-full cursor-pointer rounded-lg border-[1.5px] border-stroke bg-transparent font-medium outline-none transition file:mr-5 file:border-collapse file:cursor-pointer file:border-0 file:border-r file:border-solid file:border-stroke file:bg-whiter file:py-3 file:px-5 file:hover:bg-primary file:hover:bg-opacity-10 focus:border-primary active:border-primary disabled:cursor-default disabled:bg-whiter dark:border-form-strokedark dark:bg-form-input dark:file:border-form-strokedark dark:file:bg-white/30 dark:file:text-white dark:focus:border-primary"
                              />
                            </div>
                          </>
                        )}
                        <div className="mb-5.5">
                          <label
                            className="mb-3 block text-sm font-medium text-black dark:text-white"
                            htmlFor="serviceDescription"
                          >
                            {t("Service Description")}
                          </label>
                          <div className="relative">
                            <span className="absolute left-4.5 top-4">
                              <svg
                                className="fill-current"
                                width="20"
                                height="20"
                                viewBox="0 0 20 20"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g
                                  opacity="0.8"
                                  clipPath="url(#clip0_88_10224)"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                                    fill=""
                                  />
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                                    fill=""
                                  />
                                </g>
                                <defs>
                                  <clipPath id="clip0_88_10224">
                                    <rect width="20" height="20" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>

                            <textarea
                              className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                              name="serviceDescription"
                              id="serviceDescription"
                              onChange={(e) =>
                                setWorkDescription(e.target.value)
                              }
                              rows={6}
                              {...register("serviceDescription", {
                                required: true,
                                minLength: 30,
                                onChange: (e) => {
                                  setWorkDescription(e.target.value);
                                },
                              })}
                              placeholder={t("Write Service Description Here")}
                            />
                          </div>
                        </div>

                        <div className="flex justify-end gap-4.5">
                          <button
                            className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
                            type="submit"
                          >
                            {t("Submit Service")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-span-5 xl:col-span-3 ">
                  <div className="message-container flex justify-center items-center">
                    <p>
                      {t(
                        "You need to complete your profile first to add a service."
                      )}{" "}
                      {t("Please go to")}
                      <Link className="mx-1" to={`/updateprofile/`}>
                        {t("Update Profile")}
                      </Link>
                      .
                    </p>
                  </div>
                </div>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="flex justify-center w-full">
              <div className="message-container bg-danger bg-opacity-40 rounded-md p-5 w-full">
                {t("you can't add service until you")}{" "}
                {/* <Link to={"/addnewservice"}>{t("Services and Skills")}</Link> */}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AddNewService;
