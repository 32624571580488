import Breadcrumb from "../../components/Breadcrumb";
import fireToast from "../../hooks/fireToast";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import OrderingSteps from "../../components/OrderingSteps";
import { axiosInstance } from "../../axiosInst";
import { useSelector, useDispatch } from "react-redux";
import { setOrder } from "../../Store/Actions/orderActions";
import { toast } from "react-toastify";
import Map from "../../components/Map";
import { useTranslation } from "react-i18next";
let isUserCity = false;
const ClinetOrderInformation = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.user);
  const order = useSelector((state) => state.order);

  const [jumbersGender, setJumbersGender] = useState(
    order?.orderInfo?.JumpersGender ? order.orderInfo.JumpersGender : "all"
  );

  const [serviceName, setServiceName] = useState(
    order?.service ? order.service : ""
  );
  const [service, setService] = useState(
    order?.serviceName ? order?.serviceName : ""
  );
  const [skill, setSkill] = useState(
    order?.orderInfo?.JumpersSkills ? order?.orderInfo?.JumpersSkills : []
  );
  const [skillName, setSkillName] = useState(
    order?.skillName ? order?.skillName : []
  );
  console.log(skillName);
  const [ageMin, setAgeMin] = useState(
    order?.orderInfo?.JumpersAgeMin ? order.orderInfo.JumpersAgeMin : 18
  );
  const [ageMax, setAgeMax] = useState(
    order?.orderInfo?.JumpersAgeMax ? order.orderInfo.JumpersAgeMax : 70
  );
  const [address, setAddress] = useState(order?.orderInfo?.address);
  const [location, setLocation] = useState(order?.orderInfo?.location);
  const [nationality, setNationality] = useState(
    order?.orderInfo?.JumpersNationality
      ? order?.orderInfo?.JumpersNationality
      : "all"
  );
  const [city, setCity] = useState(order?.orderInfo?.city);
  const [cityName, setCityName] = useState();
  const [initCity, setInitCity] = useState(
    order?.initCity ? order?.initCity : "ChIJmZNIDYkDLz4R1Z_nmBxNl7o"
  );
  const [services, setServices] = useState(null);
  const [skills, setSkills] = useState();
  const [cities, setCities] = useState(null);
  const [citiesKeys, setCitiesKeys] = useState(null);
  const [positionCord, setPositionCord] = useState(
    order?.positionCord
      ? order?.positionCord
      : { lat: 24.7135517, lng: 46.6752957 }
  );

  const changeCity = (key) => {
    const { _id } = cities.find((c) => c.key === key);
    setCity(_id);
    isUserCity = true;
    setCityName(key);
    setInitCity(key);
  };

  const fetchServices = async () => {
    const result = await axiosInstance.get("/services?limit=100&isActive=true");
    setServices(result.data.data);
  };

  const fetchSkills = async () => {
    const result = await axiosInstance.get("/skills?limit=100&isActive=true");
    let arr = [];
    for (const s of result.data.data) {
      let obj = {};
      obj.value = s._id;
      obj.label = s.name;
      arr.push(obj);
    }
    setSkills(arr);
  };

  const fetchCitys = async () => {
    const result = await axiosInstance.get("/city?isActive=true");
    setCities(result.data.data);
  };

  useEffect(() => {
    axiosInstance.defaults.headers.common["lang"] = i18n.language;
    fetchServices();
    fetchSkills();
    fetchCitys();
  }, [i18n.language]);

  useEffect(() => {
    if (cities) {
      const arr = cities.map((c) => c.key);
      const { _id } = cities.find((c) => c.key === initCity);
      setCitiesKeys(arr);
      setCity(_id);
      // console.log(cities);
    }
  }, [cities]);

  const navigate = useNavigate();
  const handleSaveOrderInfo = (e) => {
    e.preventDefault();
    if (!location) return toast.error(t("Please Select Location"));

    let obj = {
      service: serviceName,
      client: userInfo._id,
      orderInfo: {
        address,
        location,
        city,
        JumpersSkills: skill,
        JumpersGender: jumbersGender,
        JumpersNationality: nationality,
        JumpersAgeMin: ageMin,
        JumpersAgeMax: ageMax,
        latAndLong: `${positionCord.lat}, ${positionCord.lng}`,
      },
      positionCord,
      skillName,
      initCity,
      serviceName: service,
    };
    dispatch(setOrder(obj));
    console.log(obj);
    toast.success(t("Order Informations Saved Successfully"));
    navigate("/ordertimingandcosts");
  };

  return (
    <>
      <OrderingSteps step1 />
      <div className="mx-auto max-w-270">
        <Breadcrumb pageName={t("Order Information")} />

        <div className="grid grid-cols-3 gap-4">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Order Information")}
                </h3>
              </div>
              <div className="p-7">
                <form onSubmit={handleSaveOrderInfo}>
                  <div className="mb-5.5">
                    <div>
                      <label
                        className="mb-3 block text-black dark:text-white"
                        htmlFor="serviceName"
                      >
                        {t("Select Service Name")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <select
                          id="serviceName"
                          name="serviceName"
                          value={serviceName}
                          onChange={(e) => {
                            setServiceName(e.target.value);
                            const s = services.find((ss) => {
                              return ss._id === e.target.value;
                            });
                            setService(s?.name);
                          }}
                          required
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          <option value="" disabled>
                            {t("Select Service")}
                          </option>
                          {services?.map((service) => (
                            <option value={service._id} key={service._id}>
                              {service.name}
                            </option>
                          ))}
                        </select>

                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill="#637381"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Nationality")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <select
                          value={nationality}
                          onChange={(e) => setNationality(e.target.value)}
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          <option value="" disabled>
                            {t("Select Naionality")}
                          </option>{" "}
                          <option value="saudi" key="saudi">
                            {t("Saudi")}
                          </option>
                          <option value="other" key="other">
                            {t("Other Nationality")}
                          </option>
                          <option value="all" key="all" selected>
                            {t("All Nationalities")}
                          </option>
                        </select>
                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill="#637381"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>

                    <div className="w-full sm:w-1/2">
                      <label className="mb-3 block text-black dark:text-white">
                        {t("City")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <select
                          value={city}
                          onChange={(e) => {
                            setCity(e.target.value);
                            const { key } = cities.find(
                              (c) => c._id === e.target.value
                            );
                            isUserCity = false;
                            setCityName(key);
                            setInitCity(key);
                          }}
                          required
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          {cities?.map((city) => (
                            <option value={city._id} key={city._id}>
                              {city?.name}
                            </option>
                          ))}
                        </select>
                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill="#637381"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5.5">
                    <div>
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Select Jumbers Gender")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <select
                          value={jumbersGender}
                          onChange={(e) => setJumbersGender(e.target.value)}
                          className="relative z-20 w-full appearance-none rounded border border-stroke bg-transparent py-3 px-4 outline-none transition focus:border-primary active:border-primary dark:border-form-strokedark dark:bg-form-input"
                        >
                          <option value="male" key="male">
                            {t("Male")}
                          </option>
                          <option value="female" key="female">
                            {t("Female")}
                          </option>
                          <option value="all" key="all" selected>
                            {t("All Genders")}
                          </option>
                        </select>
                        <span
                          className={
                            i18n.language === "ar"
                              ? "absolute left-4 top-4"
                              : "absolute right-4 top-4"
                          }
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g opacity="0.8">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M5.29289 8.29289C5.68342 7.90237 6.31658 7.90237 6.70711 8.29289L12 13.5858L17.2929 8.29289C17.6834 7.90237 18.3166 7.90237 18.7071 8.29289C19.0976 8.68342 19.0976 9.31658 18.7071 9.70711L12.7071 15.7071C12.3166 16.0976 11.6834 16.0976 11.2929 15.7071L5.29289 9.70711C4.90237 9.31658 4.90237 8.68342 5.29289 8.29289Z"
                                fill="#637381"
                              ></path>
                            </g>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="mb-5.5">
                    <div>
                      <label className="mb-3 block text-black dark:text-white">
                        {t("Skills")}
                      </label>
                      <div className="relative z-20 bg-white dark:bg-form-input">
                        <Select
                          isMulti
                          closeMenuOnSelect={false}
                          name="skills"
                          value={skillName}
                          options={skills}
                          className="w-full"
                          classNamePrefix="select"
                          required
                          onChange={(e) => {
                            console.log(e);
                            const arr = [];
                            for (const s of e) {
                              arr.push(s.value);
                            }
                            console.log(arr);
                            setSkill(arr);
                            setSkillName(e);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="mb-5.5 flex flex-col gap-5.5 sm:flex-row">
                    <div className="w-full sm:w-1/2">
                      <label
                        className="mb-3 block text-sm font-medium text-black dark:text-white"
                        htmlFor="age"
                      >
                        {t("Jumpers Age Range")}
                      </label>
                      <input
                        className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="number"
                        name="males"
                        id="males"
                        value={ageMin}
                        min="1"
                        max="98"
                        defaultValue="18"
                        placeholder="From"
                        onChange={(e) => {
                          setAgeMin(e.target.value);
                        }}
                      />
                    </div>
                    <div className="w-full sm:w-1/2">
                      <input
                        className="mt-8 w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        type="number"
                        name="females"
                        id="females"
                        value={ageMax}
                        min={ageMin}
                        max="99"
                        defaultValue="70"
                        placeholder="To"
                        onChange={(e) => {
                          setAgeMax(e.target.value);
                        }}
                      />
                    </div>
                  </div>

                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="address"
                    >
                      {t("Work Address Detalis")}
                    </label>
                    <div className="relative">
                      <span className="absolute left-4.5 top-4">
                        <svg
                          className="fill-current"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8" clipPath="url(#clip0_88_10224)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                              fill=""
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                              fill=""
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_88_10224">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <textarea
                        value={address}
                        className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="address"
                        id="address"
                        rows={2}
                        minLength={5}
                        placeholder={t("Write your address details here")}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="address"
                    >
                      {t("Work Location")}
                    </label>
                    <div className="relative">
                      <span className="absolute left-4.5 top-4">
                        <svg
                          className="fill-current"
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g opacity="0.8" clipPath="url(#clip0_88_10224)">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M1.56524 3.23223C2.03408 2.76339 2.66997 2.5 3.33301 2.5H9.16634C9.62658 2.5 9.99967 2.8731 9.99967 3.33333C9.99967 3.79357 9.62658 4.16667 9.16634 4.16667H3.33301C3.11199 4.16667 2.90003 4.25446 2.74375 4.41074C2.58747 4.56702 2.49967 4.77899 2.49967 5V16.6667C2.49967 16.8877 2.58747 17.0996 2.74375 17.2559C2.90003 17.4122 3.11199 17.5 3.33301 17.5H14.9997C15.2207 17.5 15.4326 17.4122 15.5889 17.2559C15.7452 17.0996 15.833 16.8877 15.833 16.6667V10.8333C15.833 10.3731 16.2061 10 16.6663 10C17.1266 10 17.4997 10.3731 17.4997 10.8333V16.6667C17.4997 17.3297 17.2363 17.9656 16.7674 18.4344C16.2986 18.9033 15.6627 19.1667 14.9997 19.1667H3.33301C2.66997 19.1667 2.03408 18.9033 1.56524 18.4344C1.0964 17.9656 0.833008 17.3297 0.833008 16.6667V5C0.833008 4.33696 1.0964 3.70107 1.56524 3.23223Z"
                              fill=""
                            />
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M16.6664 2.39884C16.4185 2.39884 16.1809 2.49729 16.0056 2.67253L8.25216 10.426L7.81167 12.188L9.57365 11.7475L17.3271 3.99402C17.5023 3.81878 17.6008 3.5811 17.6008 3.33328C17.6008 3.08545 17.5023 2.84777 17.3271 2.67253C17.1519 2.49729 16.9142 2.39884 16.6664 2.39884ZM14.8271 1.49402C15.3149 1.00622 15.9765 0.732178 16.6664 0.732178C17.3562 0.732178 18.0178 1.00622 18.5056 1.49402C18.9934 1.98182 19.2675 2.64342 19.2675 3.33328C19.2675 4.02313 18.9934 4.68473 18.5056 5.17253L10.5889 13.0892C10.4821 13.196 10.3483 13.2718 10.2018 13.3084L6.86847 14.1417C6.58449 14.2127 6.28409 14.1295 6.0771 13.9225C5.87012 13.7156 5.78691 13.4151 5.85791 13.1312L6.69124 9.79783C6.72787 9.65131 6.80364 9.51749 6.91044 9.41069L14.8271 1.49402Z"
                              fill=""
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_88_10224">
                              <rect width="20" height="20" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <textarea
                        value={location}
                        className="w-full rounded border border-stroke bg-gray py-3 pl-11.5 pr-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                        name="address"
                        id="address"
                        rows={2}
                        minLength={5}
                        disabled
                        style={{ resize: "none" }}
                        onChange={(e) => {
                          setAddress(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>
                  <div className="my-2">
                    <Map
                      setAddressProp={setLocation}
                      clickedCity={cityName}
                      initCity={initCity}
                      positionCord={positionCord}
                      setPositionCord={setPositionCord}
                      citiesKeys={citiesKeys}
                      location={location}
                      changeCity={changeCity}
                      isUserCity={isUserCity}
                    />
                  </div>
                  <div className="flex justify-end gap-4.5 mt-3">
                    <button
                      className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
                      type="submit"
                      // onClick={fireToast}
                    >
                      {t("Save")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClinetOrderInformation;
