import React from "react";
import { Link, useNavigate } from "react-router-dom";

const NotFound = () => {
  const navigate = () => {
    window.location.href = "/";
  };
  const navigatee = useNavigate();
  return (
    <div>
      <div id="notfound">
        <button
          onClick={() => navigatee(-1)}
          className="bgteal p-3 rounded-lg text-white font-semibold"
        >
          Go Back
        </button>

        <div className="notfound">
          <div className="notfound-404">
            <h1>Oops!</h1>
            <h2>404 - The Page can't be found</h2>
          </div>
          <button
            onClick={navigate}
            className="bgteal p-3 rounded-lg shadow-lg text-3xl text-white font-semibold"
          >
            Go TO Homepage
          </button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
