import { Link } from "react-router-dom";
import Breadcrumb from "../../components/Breadcrumb";
import { useEffect, useState } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import moment from "moment";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import noOffers from "../../images/product/SEO.webp";
import { GrNext, GrPrevious } from "react-icons/gr";

const AllInvoices = () => {
  const { t, i18n } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const [invoice, setInvoice] = useState([]);
  console.log(userInfo);
  const Base_URL = "https://api.jumpersksa.online/";
  const [page, setPage] = useState(1);
  const [pages, setPages] = useState();
  const [results, setResults] = useState(0);
  const [numOfPages, setNumOfPages] = useState();
  const [lastPage, setLastPage] = useState();
  useEffect(() => {
    axios
      .get(`${Base_URL}invoices/user_invoices?limit=4&page=${page}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
          // Authorization:
          // "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NTAyOGYwMDgzYjdmZWFhNDk5YjIyYmYiLCJ1c2VyUm9sZSI6ImNsaWVudCIsImlhdCI6MTY5NzQ0MzgxNH0.EOJw3hVOKNcMSTYvare_sM_WI8KZr9sUCy4cFvBeEgo",
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setInvoice(res?.data?.data);
        setPages(res?.data?.documentsCounts);
        // setNumOfPage(res?.data?.paginationResult?.numberOfPages);
        if (res?.data?.paginationResult?.numberOfPages > 5) {
          setNumOfPages(5);
        } else {
          setNumOfPages(res?.data?.paginationResult?.numberOfPages);
        }
        setLastPage(res?.data?.paginationResult?.numberOfPages);
        setResults(res?.data?.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [page, i18n.language]);
  let pagesArr = [];
  // useEffect(() => {
  //   if (numOfPages > 5) {
  //     setNumOfPages(5);
  //   }
  // }, [numOfPages]);

  for (let i = 1; i <= numOfPages; i++) {
    pagesArr.push(i);
  }
  console.log(pagesArr, numOfPages);

  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrev = () => {
    setPage(page - 1);
  };
  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <>
      {invoice.length > 0 ? (
        <>
          <Breadcrumb pageName={t("All Invoices")} />
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="text-center">
                  <tr className="bg-gray-2 text-center dark:bg-meta-4">
                    <th className="min-w-[50px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("Invoice Number")}
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Release invoice date")}
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("Total Price With VAT")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Billed To")}
                    </th>

                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("Details")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {invoice?.map((invoice, index) => (
                    <tr key={invoice?._id} className="text-center">
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <p className="text-black dark:text-white">
                          {invoice?.invoiceNumber}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {moment(invoice?.createdAt)
                            .utc()
                            .format("YYYY-MM-DD")}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black text-center dark:text-white">
                          {invoice?.total} {t("SAR")}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          {invoice?.client?.firstName}{" "}
                          {invoice?.client?.lastName}
                        </p>
                      </td>

                      <td className="border-b flex items-center justify-center border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center space-x-3.5">
                          <Link
                            to={`/invoicedetails/${invoice?._id}`}
                            className="hover:text-primary"
                          >
                            <svg
                              className="fill-current"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                fill=""
                              />
                              <path
                                d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                fill=""
                              />
                            </svg>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex lg:flex-row justify-between mt-3 items-center">
            <nav
              aria-label="Pagination"
              class="flex justify-center items-center text-gray-600 mt-8 lg:mt-0 "
            >
              
              <div
                className="flex justify-around items-center bg-gradient-to-r from-[#000]  to-[#2e414ade] text-white rounded-3xl p-2"
                dir="ltr"
              >
                <button
                  onClick={handlePrev}
                  disabled={page === 1}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === 1 ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === 1 ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrPrevious size={15} />
                  </div>
                </button>
                {pagesArr.map((page) => (
                  <div
                    onClick={() => setPage(page)}
                    className="flex mx-1 justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                  >
                    <div
                      className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                        page === page ? "bg-white text-black" : ""
                      }`}
                    >
                      {page}
                    </div>
                  </div>
                ))}
                {lastPage > 5 ? (
                  <>
                    <span
                      className="cursor-pointer"
                      // onClick={setNumOfPage(lastPage)}
                    >
                      ...
                    </span>

                    <div
                      onClick={() => setPage(lastPage)}
                      className="flex mx-1 justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                    >
                      <div
                        className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                          page === page ? "bg-white text-black" : ""
                        }`}
                      >
                        {lastPage}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <button
                  onClick={handleNext}
                  disabled={page === lastPage}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === lastPage ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === lastPage ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrNext size={15} />
                  </div>
                </button>
              </div>
            </nav>
            <p>
              {t("showing")} <span className="text-[#60a9bf]">{results}</span>{" "}
              {t("pages")} {t("of")}{" "}
              <span className="text-[#60a9bf]">{pages}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center my-5">
            <div className="m-auto w-4/5 flex justify-center">
              <img
                src={noOffers}
                alt="No Offers Available"
                className="w-50 h-50"
              />
            </div>
            <div className="text-center">
              <p className="font-bold text-lg">
                {t("Sorry! you don't have Invoices Yet")}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AllInvoices;
