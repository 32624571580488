import React from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import CreditCards from "../../components/CreditCards";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Controller } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import { use } from "i18next";
import { setUser } from "../../Store/Actions/userActions";

// toast.error(err.response.data.err, {
//   position: toast.POSITION.TOP_CENTER,
// });
const PaymentSettings = () => {
  const dispatch = useDispatch();

  const { t, i18n } = useTranslation();
  const { userInfo } = useSelector((state) => state.user);
  const [isSelected, setIsSelected] = useState(null);
  const [transactionTo, setTransactionTo] = useState(
    userInfo?.bankInfo?.accountNo ? userInfo?.bankInfo?.accountNo : ""
  );
  const [iban, setIban] = useState(
    userInfo?.bankInfo?.iban ? userInfo?.bankInfo?.iban.slice(2) : ""
  );
  let newiban;
  if (iban) {
    newiban = iban.slice(2);
    console.log(newiban);
  }
  console.log(iban);
  const [bankCode, setbankCode] = useState(
    userInfo?.bankInfo?.bankCode ? userInfo?.bankInfo?.bankCode : ""
  );
  const [bank, setBank] = useState([]);
  const [selectedBank, setSelectedBank] = useState(
    userInfo?.bankInfo?.bank ? userInfo?.bankInfo?.bank : ""
  );
  console.log(bankCode);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/bank_accounts?isActive=true`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setBank(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "onChange",
  });

  const onSubmit = async (e) => {
    console.log({ transactionTo, iban });
  };
  const updateBank = async (e) => {
    console.log({ transactionTo, iban });
    e.preventDefault();
    if (!selectedBank) return toast.error(t("Please select bank"));
    if (!transactionTo) return toast.error(t("Please enter account number"));
    if (!iban) return toast.error(t("Please enter IBAN"));
    if (!bankCode) return toast.error(t("Please enter bank code"));
    try {
      const res = await axios.put(
        `https://api.jumpersksa.online/users/update_profile`,
        {
          bankInfo: {
            bank: selectedBank,
            accountNo: transactionTo,
            iban: `SA${iban}`,
            bankCode: bankCode,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      console.log(res);
      dispatch(setUser(res.data.data));
      toast.success(t("Bank information updated succefully"));
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    } catch (error) {
      console.log(error);
    }
    console.log("Updated bank code:", bankCode);

    // .then((res) => {
    //   console.log(res);
    //   toast.success("Bank information updated succefully", {
    //     position: toast.POSITION.TOP_CENTER,
    //   });
    // })
    // .catch((err) => {
    //   console.error(err);
    // });
    // toast.success("Bank information updated succefully");
    console.log("update bank");
  };
  const [loading, setLoading] = useState(true);

  return loading ? (
    <Loader />
  ) : (
    <>
      {/* <PaymentSteps step1 step2 /> */}
      <Breadcrumb pageName={t("Payment Settings")} />
      <h1 className="text-xl font-bold text-center m-5">
        {t("Please Select your Bank")}
      </h1>
      <Swiper
        // install Swiper modules
        modules={[
          Navigation,
          Pagination,
          // Scrollbar,
          // A11y,
          Controller,
        ]}
        slidesPerView={3}
        spaceBetween={40}
        navigation
        pagination={{ clickable: true }}
        // scrollbar={{ draggable: true }}
        onSwiper={(swiper) => console.log(swiper)}
        onSlideChange={() => console.log("slide change")}
        breakpoints={{
          "@0.00": {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          "@0.75": {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          "@1.00": {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
      >
        {bank.map((bankItem, id) => (
          <SwiperSlide>
            <CreditCards
              key={bankItem._id}
              option={bankItem}
              selected={selectedBank === bankItem._id}
              onClick={() => {
                setSelectedBank(bankItem._id);
                setbankCode(bankItem?.bankCode);
              }}

              // onChange={(value) => setbankCode(value)}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* <div className="flex justify-center">
        <div className="flex justify-center gap-2">
          {bank.map((bankItem, id) => (
            <CreditCards
              key={bankItem._id}
              option={bankItem}
              selected={selectedBank === bankItem._id}
              onClick={() => setSelectedBank(bankItem._id)}
            />
          ))}
        </div>
      </div> */}

      <hr className="my-5" />

      <div className="mx-auto max-w-270">
        <div className="grid grid-cols-3 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Bank Account")}
                </h3>
              </div>
              <div className="p-7">
                <form>
                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="creditnumber"
                    >
                      {t("Account Number")}
                    </label>
                    <input
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="creditnumber"
                      id="creditnumber"
                      defaultValue={userInfo?.bankInfo?.accountNo}
                      placeholder="-----------------"
                      value={transactionTo}
                      {...register("creditnumber", {
                        required: true,
                        pattern: /^[0-9]{14}$/,
                        onChange: (e) => {
                          setTransactionTo(e.target.value);
                        },
                      })}
                    />
                    {errors.creditnumber && (
                      <span className="text-danger">
                        {errors.creditnumber.type === "required" &&
                          t("This field is required")}{" "}
                        {errors.creditnumber.type === "pattern" &&
                          t("Invalid credit number")}
                      </span>
                    )}
                  </div>

                  {/* <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="iban"
                    >
                      {t("IBAN")}
                    </label>
                    <input
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="iban"
                      id="iban"
                    />
                  </div> */}
                  <div className="relative mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="iban"
                    >
                      {t("IBAN")}
                    </label>
                    <small className="linkCC">
                      {t("(Note: you don't have to enter 'SA' in the start)")}
                    </small>
                    <div className="w-full flex" dir="ltr">
                      <span className="text-gray-500 spanPhone dark:text-gray-400 px-2.5 border-stroke bg-transparent outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary">
                        SA
                      </span>
                      <input
                        type="number"
                        placeholder={t("---- ---- ---- ---- ---- ----")}
                        required
                        name="iban"
                        id="iban"
                        defaultValue={iban}
                        {...register("iban", {
                          required: true,
                          // pattern:
                          //   /^(?:[A-Z]{2}\d{2}(?:\s?\d{4}){5}|[A-Z]{2}\d{22})$/,
                          onChange: (e) => {
                            setIban(e.target.value);
                          },
                        })}
                        className={
                          i18n.language === "ar"
                            ? "w-full inputPhone border border-stroke bg-transparent py-4 pr-6 pl-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                            : "w-full inputPhone border border-stroke bg-transparent py-4 pl-6 pr-10 outline-none focus:border-primary focus-visible:shadow-none dark:border-form-strokedark dark:bg-form-input dark:focus:border-primary"
                        }
                      />
                    </div>
                    {errors.iban && (
                      <span className="text-danger">
                        {errors.iban.type === "required" &&
                          t("This field is required")}
                        {/* {errors.iban.type === "pattern" &&
                          "Invalid IBAN number"} */}
                      </span>
                    )}
                  </div>
                  <div className="mb-5.5">
                    <label
                      className="mb-3 block text-sm font-medium text-black dark:text-white"
                      htmlFor="bankCode"
                    >
                      {t("Bank Code")}
                    </label>
                    <input
                      className="w-full rounded border border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary"
                      type="text"
                      name="bankCode"
                      id="bankCode"
                      readOnly
                      placeholder={t("Enter Bank Code")}
                      value={bankCode}
                      {...register("bankCode", {
                        required: true,
                        onChange: (e) => {
                          setbankCode(e.target.value);
                        },
                      })}
                    />
                    {errors.bankCode && (
                      <span className="text-danger">
                        {errors.bankCode.type === "required" &&
                          t("This field is required")}
                        {errors.bankCode.type === "pattern" &&
                          t("Invalid Bank Code")}
                      </span>
                    )}
                  </div>

                  {/* <div className="flex justify-end gap-4.5">
                    <button
                      className="flex justify-center rounded bg-primary py-2 px-6 font-medium text-gray hover:shadow-1"
                      // onClick={updateBank}
                      // type="submit"
                    >
                      Save Data
                    </button>
                  </div> */}
                  <button
                    type="submit"
                    className="flex cursor-pointer items-center justify-center gap-2 rounded bgteal py-1 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4"
                    onClick={updateBank}
                  >
                    {t("Save")}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSettings;
