import React, { useEffect, useState } from "react";
import PaymentSteps from "../../components/PaymentSteps";
import Breadcrumb from "../../components/Breadcrumb";
import axios from "axios";
import { FcCurrencyExchange, FcMoneyTransfer } from "react-icons/fc";
import { GrTransaction } from "react-icons/gr";
import { GiTakeMyMoney } from "react-icons/gi";
import Loader from "../../common/Loader";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { MdMoneyOff } from "react-icons/md";
import { useSelector } from "react-redux";

// FcMoneyTransfer
//FcCurrencyExchange
// GiTakeMyMoney
//GrTransaction
const PaymentInfo = () => {
  const { t } = useTranslation();
  const user = useSelector((state) => state.user.userInfo);
  const [id, setId] = useState();
  const [wallet, setWallet] = useState();
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/users/user_wallet`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setId(res.data.data._id);
        setWallet(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);
  const [loading, setLoading] = useState(true);
  const withDrawBalance = () => {
    if (user.bankInfo.accountNo && user.bankInfo.iban && user.bankInfo.bankCode) {
      setLoading(true);
      axios
        .put(
          `https://api.jumpersksa.online/users/withdraw_money`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          console.log(res);
          setLoading(false);
          setWallet(res.data?.wallet);
          toast.success(t("Balance Withdrawn Successfully"));
        })
        .catch((err) => {
          toast.error(err?.response?.data?.msg);
          setLoading(false);
          console.error(err);
        });
    } else {
          toast.error("your bank information is not complete");
    }
    
  };
  return loading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumb pageName={t("Payment Information")} />
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-2 2xl:gap-7.5">
        <div class="flex flex-col rounded-sm border border-stroke bg-white py-3 px-3 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold text-black dark:text-white flex items-center justify-between">
              {t("Total Balance")}
              <FcCurrencyExchange />
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
              {wallet?.total}
              {""}
              <span className="mx-1">{t("SAR")}</span>
            </p>
          </div>
        </div>
        <div class="flex flex-col rounded-sm border border-stroke bg-white py-3 px-3 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold text-black dark:text-white flex items-center justify-between">
              {t("Deducted Balance")}
              <MdMoneyOff fill="red" size={20} />
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
              {wallet?.deducted}
              <span className="mx-1">{t("SAR")}</span>
            </p>
          </div>
        </div>
        <div class="flex flex-col rounded-sm border border-stroke bg-white py-3 px-3 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold text-black dark:text-white flex items-center justify-between">
              {t("Withdrawn Balance")}
              <FcMoneyTransfer />
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
              {wallet?.withdrawn}
              <span className="mx-1">{t("SAR")}</span>
            </p>
          </div>
        </div>

        <div class="flex flex-col rounded-sm border border-stroke bg-white py-3 px-3 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold  flex items-center justify-between">
              <span className="text-black dark:text-white">
                {t("Total Free Balance")}{" "}
              </span>
              <GrTransaction color="green" />
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
              {wallet?.free}
              <span className="mx-1">{t("SAR")}</span>
            </p>
          </div>
        </div>

        <div class="flex flex-col rounded-sm border border-stroke bg-white py-3 px-3 shadow-default dark:border-strokedark dark:bg-boxdark">
          <div class="p-4 md:p-5">
            <h3 class="text-lg font-bold text-black dark:text-white flex items-center justify-between">
              {t("outstanding balance")} <GiTakeMyMoney />
            </h3>
            <p class="mt-2 text-gray-800 dark:text-gray-400">
              {wallet?.outstanding}
              <span className="mx-1">{t("SAR")}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <button
          onClick={withDrawBalance}
          className="inline-flex my-5 items-center justify-center rounded-md border bgteal py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
        >
          {t("Withdraw Your Balance Now")}
        </button>
      </div>
      <hr />
      {/* <div>
        <h3 class=" my-5 text-lg font-bold text-black dark:text-white">
          Transaction History
        </h3>
        <p class="mt-2 text-gray-800 dark:text-gray-400">
          12 SAR were withdrawn on 02-12-2022 at 12:00 am
        </p>
      </div> */}
    </>
  );
};

export default PaymentInfo;
