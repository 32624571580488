import React from "react";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import CoverOne from "../../images/cover/cover-01.png";
import userSix from "../../images/user/default.png";
import axios from "axios";
import { useState } from "react";
import { useSelector } from "react-redux";
import { BsPersonVideo2 } from "react-icons/bs";
import coverPic from "../../images/cover/clark-tibbs-oqStl2L5oxI-unsplash.jpg";
import { RiDoubleQuotesL, RiDoubleQuotesR } from "react-icons/ri";
import { useTranslation } from "react-i18next";
import { AiTwotoneStar } from "react-icons/ai";
import Loader from "../../common/Loader";

const FavDetails = () => {
  console.log("JumperProfilerrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrrr");
  const { t, i18n } = useTranslation();
  const data = localStorage.getItem("role");
  const { userInfo } = useSelector((state) => state.user);
  const Base_URL = "https://api.jumpersksa.online/";
  const { id } = useParams();

  const [jumper, setJumper] = useState([]);
  const [loading, setLoading] = useState(true);
  const [cv, setCv] = useState();

  useEffect(() => {
    axios
      .get(`${Base_URL}users/${id}`, {
        headers: {
          lang: i18n.language,
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      })
      .then((res) => {
        console.log(res);
        setLoading(false);
        setJumper(res.data.data);
        setCv(res?.data?.data?.cv);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [i18n.language, id]);
  function changeNationalityName(nationality) {
    switch (nationality) {
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("Not selected");
    }
  }
  function changeGenderName(gender) {
    switch (gender) {
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("Not selected");
    }
  }
  return loading ? (
    <Loader />
  ) : (
    <>
      <Breadcrumb pageName={t("Jumper Profile")} />

      <div className="overflow-hidden rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
        <div className="relative z-20 h-35 md:h-65">
          <img
            src={coverPic}
            alt="profile cover"
            className="h-full w-full rounded-tl-sm rounded-tr-sm object-cover object-center"
          />
        </div>

        <div className="px-4 pb-6 text-center lg:pb-8 xl:pb-11.5">
          <div className="relative z-30 mx-auto -mt-22 h-30 w-full max-w-30 rounded-full bg-white/20 p-1 backdrop-blur sm:h-44 sm:max-w-44 sm:p-3">
            <div className="relative drop-shadow-2">
              <div className="divImg">
                <img
                  src={jumper?.image ? `${Base_URL}${jumper?.image}` : userSix}
                  alt="profile"
                  id="profileImg"
                  className="rounded-full h-39 w-39 pb-0.5"
                />
              </div>
            </div>
          </div>

          <div className="mt-4">
            <h3 className="mb-1.5 text-2xl font-semibold text-black dark:text-white">
              {jumper?.firstName} {jumper?.lastName}
            </h3>
            <p className="font-medium flex justify-center">
              <span className="flex items-center mx-1" title="your rating">
                ({jumper?.rating?.toFixed(1)}{" "}
                <AiTwotoneStar
                  className="inline ml-1 starRateInProfile"
                  fill="gold"
                />
                )
              </span>
            </p>

            <div
              className={`pinfo ${
                data !== "jumper" || userInfo?.isCompany
                  ? "pinfo-full-height"
                  : ""
              } overflow-auto rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark`}
            >
              <div className="p-4 flex justify-center text-base md:text-lg lg:text-xl titleOfSkills">
                {t("'s Personal informations")}
              </div>
              <hr className="px-3 m-3 text-black opacity-40" />
              <div className="flex items-center justify-start gap-1  border-stroke px-4 dark:border-strokedark xsm:flex-row">
                <span className="texttColor">{t("Age")}:</span>
                <span className="font-semibold text-black dark:text-white">
                  {jumper?.age ? jumper?.age : "not selected"}
                </span>
              </div>
              <div className="flex items-center justify-start gap-1  border-stroke px-4 dark:border-strokedark xsm:flex-row">
                <span className="texttColor">{t("Gender")}:</span>
                <span className="font-semibold text-black dark:text-white">
                  {jumper?.gender
                    ? changeGenderName(jumper?.gender)
                    : "not selected"}
                </span>
              </div>
              <div className="flex  items-center justify-start gap-1 px-4 xsm:flex-row">
                <p className="texttColor">{t("Nationality")}:</p>
                <p className="font-semibold text-black dark:text-white">
                  {jumper?.nationality
                    ? changeNationalityName(jumper?.nationality)
                    : "not selected"}
                </p>
              </div>
              <div className="flex  items-center justify-start gap-1 px-4 xsm:flex-row">
                <p className="texttColor">{t("City")}:</p>
                <p className="font-semibold text-black dark:text-white">
                  {jumper?.city?.name ? jumper?.city?.name : "not selected"}
                </p>
              </div>
              {jumper?.about && (
                <>
                  <div className="flex justify-center">
                    <div className="flex items-center quote p-8 justify-center">
                      <p className="flex">
                        <RiDoubleQuotesL />
                        {jumper?.about}
                        <RiDoubleQuotesR />
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            {jumper?.skills?.length ? (
              <>
                <p className="titleOfSkills flex justify-center">
                  {t("'s skills")}
                </p>
                <div className="skillContainer mt-8 flex gap-3 ">
                  {jumper?.skills.map(
                    (skill) => (
                      // skill.isActive && (
                      <div class="bg-white p-2  leading-none flex justify-evenly items-center dark:border-strokedark dark:bg-[#37404F] text-black dark:text-white rounded-md border border-stroke py-2.5 shadow-1">
                        {skill.name}
                      </div>
                    )
                    // )
                  )}
                </div>
              </>
            ) : (
              <p>{t("this jumper don't have skills")}</p>
            )}
            <hr className="my-4" />

            <div>
              <a
                href={`https://api.jumpersksa.online/${cv}`}
                target="_blank"
                className="w-full cursor-pointer rounded-lg border border-meta-3 bgteal p-4 text-white transition hover:bg-opacity-90 mt-10 flex justify-center items-center"
                rel="noreferrer"
              >
                {jumper?.firstName}'s CV{" "}
                <BsPersonVideo2 className="mx-2" fontSize={20} />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FavDetails;
