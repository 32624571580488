import Breadcrumb from "../../components/Breadcrumb";
import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ContractModal from "../../components/ContractModal";
import { useTranslation } from "react-i18next";

const OfferDetails = ({ socket }) => {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [offer, setOffer] = useState();
  const { userInfo } = useSelector((state) => state.user);
  const [showContractModal, setShowContractModal] = useState(false);
  useEffect(() => {
    axios
      .get(`https://api.jumpersksa.online/offers/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),

          lang: i18n.language,
        },
      })
      .then((res) => {
        console.log(res);
        setOffer(res.data.data);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);

  const [rows, setRows] = useState(
    localStorage.getItem("alertSettings")
      ? JSON.parse(localStorage.getItem("alertSettings"))
      : []
  );
  useEffect(() => {
    // storing input name
    localStorage.setItem("alertSettings", JSON.stringify(rows));
  }, [rows]);

  console.log(offer);
  const startDate = new Date(offer?.order?.startDate);
  const endDate = new Date(offer?.order?.endDate);
  const acceptStatus = () => {
    axios
      .put(
        `https://api.jumpersksa.online/offers/${id}`,
        {
          status: "accepted",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        toast.success(t("offer accepted succfully!"));
        setOffer({ ...offer, status: res?.data?.data?.status });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.err);
      });
  };
  const rejectStatus = () => {
    axios
      .put(
        `https://api.jumpersksa.online/offers/${id}`,
        {
          status: "rejected",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);

        toast.success(t("offer rejected succfully!"));
        setOffer({ ...offer, status: res?.data?.data?.status });
      })
      .catch((err) => {
        console.error(err);
        toast.error(err?.response?.data?.err);
      });
  };

  const sendNotoficationOfReject = (client, fname, lname) => {
    const notification = {
      users: [client],
      type: "order offer",
      title_en: "offer refused",
      title_ar: "عرض مرفوض",
      desc_en: "an offer has been refused check it out to more info",
      desc_ar: "تم رفض عرضك يرجى التحقق منه",
      link: offer.order._id,
      createdAt: Date.now(),
    };
    socket.emit("new notfication", notification);
  };

  const changeNameOfGender = (name) => {
    switch (name) {
      case "all":
        return t("All");
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("All");
    }
  };
  const changeNameOfNationalities = (name) => {
    switch (name) {
      case "all":
        return t("All Nationalities");
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("All Nationalities");
    }
  };
  return (
    <>
      <div className="mx-auto max-w-2x">
        <Breadcrumb pageName={t("Offer Details")} />
        {offer?.status === "accepted" && (
          <>
            {/* <div className="flex flex-col flex-wrap justify-center gap-4 mb-5 bg-success bg-opacity-40 p-2">
              <p className="text-2xl font-semibold text-success">
                {t("Offer Acctepted")}
              </p>

              <div className="flex">
                <p className="text-success"> {t("did u want to see")}</p>{" "}
                <Link to={`/contracts/${id}`} className="mx-1">
                  {t("contract")}
                </Link>
              </div>
            </div> */}
            <div className="flex w-full border-l-6 gap-4 mb-5 border-[#34D399] bg-[#34D399] bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-[#34D399] dark:bg-opacity-30 md:p-9">
              <div className="mr-5 flex h-9 w-full max-w-[36px] items-center justify-center rounded-lg bg-[#34D399]">
                <svg
                  width="16"
                  height="12"
                  viewBox="0 0 16 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.2984 0.826822L15.2868 0.811827L15.2741 0.797751C14.9173 0.401867 14.3238 0.400754 13.9657 0.794406L5.91888 9.45376L2.05667 5.2868C1.69856 4.89287 1.10487 4.89389 0.747996 5.28987C0.417335 5.65675 0.417335 6.22337 0.747996 6.59026L0.747959 6.59029L0.752701 6.59541L4.86742 11.0348C5.14445 11.3405 5.52858 11.5 5.89581 11.5C6.29242 11.5 6.65178 11.3355 6.92401 11.035L15.2162 2.11161C15.5833 1.74452 15.576 1.18615 15.2984 0.826822Z"
                    fill="white"
                    stroke="white"
                  ></path>
                </svg>
              </div>
              <div className="w-full">
                <p className=" leading-relaxed text-body mx-1 text-lg">
                  {t("Offer Acctepted")}

                  <div className="flex">
                    <p className="text-body"> {t("did u want to see")}</p>{" "}
                    <Link to={`/contracts/${id}`} className="mx-1 inline">
                      {t("contract")}
                    </Link>
                  </div>
                </p>
              </div>
            </div>
          </>
        )}
        {offer?.status === "rejected" && (
          <>
            <div className="flex w-full gap-4 mb-5 border-l-6 border-danger bg-danger bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-danger dark:bg-opacity-30 md:p-9">
              <div className="mr-5 flex h-15 w-15 items-center justify-center rounded-lg bg-danger bg-opacity-30">
                <svg
                  width="25"
                  height="25"
                  viewBox="0 0 19 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                    fill="#bb3f32"
                  ></path>
                </svg>
              </div>
              <div className="w-full flex items-center">
                <p className="leading-relaxed text-[#d0685c] mx-1 text-lg">
                  <p>{t("Offer Rejected")}</p>
                </p>
              </div>
            </div>
          </>
        )}
        {offer?.status === "pending" &&
          new Date(offer?.order?.startDate) < new Date() && (
            <>
              <div className="flex w-full gap-4 mb-5 border-l-6 border-warning bg-warning bg-opacity-[15%] px-7 py-8 shadow-md dark:bg-warning dark:bg-opacity-30 md:p-9">
                <div className="mr-5 flex h-15 w-15 items-center justify-center rounded-lg bg-warning bg-opacity-30">
                  <svg
                    width="25"
                    height="25"
                    viewBox="0 0 19 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.50493 16H17.5023C18.6204 16 19.3413 14.9018 18.8354 13.9735L10.8367 0.770573C10.2852 -0.256858 8.70677 -0.256858 8.15528 0.770573L0.156617 13.9735C-0.334072 14.8998 0.386764 16 1.50493 16ZM10.7585 12.9298C10.7585 13.6155 10.2223 14.1433 9.45583 14.1433C8.6894 14.1433 8.15311 13.6155 8.15311 12.9298V12.9015C8.15311 12.2159 8.6894 11.688 9.45583 11.688C10.2223 11.688 10.7585 12.2159 10.7585 12.9015V12.9298ZM8.75236 4.01062H10.2548C10.6674 4.01062 10.9127 4.33826 10.8671 4.75288L10.2071 10.1186C10.1615 10.5049 9.88572 10.7455 9.50142 10.7455C9.11929 10.7455 8.84138 10.5028 8.79579 10.1186L8.13574 4.75288C8.09449 4.33826 8.33984 4.01062 8.75236 4.01062Z"
                      fill="#FBBF24"
                    ></path>
                  </svg>
                </div>
                <div className="w-full flex items-center">
                  <p className="leading-relaxed text-[#D0915C] mx-1 text-lg">
                    <p>{t("Offer Ended")}</p>
                  </p>
                </div>
              </div>
            </>
          )}{" "}
        {offer?.status === "pending" &&
          new Date(offer?.order?.startDate) > new Date() && (
            <div className="flex flex-wrap justify-center gap-4 mb-5">
              <button
                onClick={() => {
                  setShowContractModal(true);
                }}
                className="inline-flex items-center justify-center rounded-md bg-success py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("Accept")}
              </button>
              {showContractModal && (
                <ContractModal
                  closeModal={() => setShowContractModal(false)}
                  onSubmit={() => {
                    setShowContractModal(false);
                  }}
                  defaultValue
                  id={id}
                  socket={socket}
                />
              )}

              <button
                onClick={() => {
                  rejectStatus(id);
                  sendNotoficationOfReject(
                    offer?.client?._id,
                    userInfo?.firstName,
                    userInfo?.lastName
                  );
                }}
                className="inline-flex items-center justify-center rounded-md bg-danger py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("Reject")}
              </button>
              <Link
                to={`/contracts/${id}`}
                className="inline-flex items-center justify-center rounded-md bg-warning py-4 px-10 text-center font-medium text-white hover:bg-opacity-90 lg:px-8 xl:px-10"
              >
                {t("contract")}
              </Link>
            </div>
          )}
        <div className="grid grid-cols-5 gap-8">
          <div className="col-span-5 xl:col-span-3">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Offer Info Data")}
                </h3>
              </div>
              <div className="p-7">
                <div class="grid grid-cols-1 divide-y ">
                  <div class="flex flex-row justify-between pb-3">
                    <div>
                      <p>{t("Service Name")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.service?.name}</p>
                    </div>
                  </div>

                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Nationality")}</p>
                    </div>
                    <div>
                      {/* <p>{offer?.order?.orderInfo?.JumpersNationality}</p> */}
                      <p>
                        {changeNameOfNationalities(
                          offer?.order?.orderInfo?.JumpersNationality
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Gender")}</p>
                    </div>
                    <div>
                      <p>
                        {changeNameOfGender(
                          offer?.order?.orderInfo?.JumpersGender
                        )}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("City")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.orderInfo?.city?.name}</p>
                    </div>
                  </div>
                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Address")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.orderInfo?.location}</p>
                    </div>
                  </div>
                  <div class="flex flex-row justify-between pb-3 pt-3">
                    <div>
                      <p>{t("Age Range")}</p>
                    </div>
                    <div>
                      <p>
                        {t("From")} {offer?.order?.orderInfo?.JumpersAgeMin}{" "}
                        {t("To")} {offer?.order?.orderInfo?.JumpersAgeMax}
                      </p>
                    </div>
                  </div>

                  <div class="flex flex-col justify-between pb-3 pt-3">
                    <div>
                      <p className="mb-1">{t("Work Description")}</p>
                    </div>
                    <div>
                      <p>{offer?.order?.workDesc}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-5 xl:col-span-2">
            <div className="rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark">
              <div className="border-b border-stroke py-4 px-7 dark:border-strokedark">
                <h3 className="font-medium text-black dark:text-white">
                  {t("Timing And Costing Data")}
                </h3>
              </div>
              <div className="p-7">
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Hours")}</p>
                  </div>
                  <div>
                    <p>
                      {offer?.order?.timeAndCost.workHours} {t("Hours")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Days")}</p>
                  </div>
                  <div>
                    <p>
                      {offer?.order?.timeAndCost.dayNo} {t("Days")}
                    </p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Working Type")}</p>
                  </div>
                  <div>
                    <p>
                      {offer?.order?.workDays?.length ? (
                        <>{t("Seprated Days")}</>
                      ) : (
                        <>{t("Connected Days")}</>
                      )}
                    </p>
                    <p></p>
                  </div>
                </div>
                {/* <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Pending Period")}</p>
                  </div>
                  <div>
                    <p>{t("24 Hour")}</p>
                  </div>
                </div> */}
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Start Date")}</p>
                  </div>
                  <div>
                    <p>{startDate.toLocaleString()}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("End Date")}</p>
                  </div>
                  <div>
                    <p>{endDate.toLocaleString()}</p>
                  </div>
                </div>
                <div class="flex flex-row justify-between pb-3 pt-3">
                  <div>
                    <p>{t("Total Cost")}</p>
                  </div>
                  <div>
                    {(
                      offer?.order?.timeAndCost?.jumperPrice -
                      offer?.order?.timeAndCost?.jumperPrice *
                        (offer?.order?.siteRatio / 100)
                    ).toFixed(2)}{" "}
                    {t("SAR")}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OfferDetails;
