import { Link, useNavigate } from "react-router-dom";
import { AiOutlineMessage } from "react-icons/ai";
import { useEffect, useState } from "react";
import axios from "axios";
import "./Orders.css";
import Breadcrumb from "../../components/Breadcrumb";
import Loader from "../../common/Loader";
import noOffers from "../../images/product/SEO.webp";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { FaGreaterThan } from "react-icons/fa6";
import { FaLessThan } from "react-icons/fa6";
import { GrNext, GrPrevious } from "react-icons/gr";

const Orders = ({ socket }) => {
  const { t, i18n } = useTranslation();
  const data = localStorage.getItem("role");
  const { userInfo } = useSelector((state) => state.user);
  const [orders, setOrders] = useState([]);
  const limit = 4;
  const [page, setPage] = useState(1);
  const [numOfPage, setNumOfPage] = useState(null);
  const [documentsCounts, setDocumentsCounts] = useState(0);
  const [lastPage, setLastPage] = useState();
  const [pages, setPages] = useState();
  const [results, setResults] = useState(0);
  const [id, setId] = useState(null);

  useEffect(() => {
    socket.on("update order recieved", () => {
      getOrders();
    });
  }, []);

  useEffect(() => {
    getOrders();
  }, [page]);

  const getOrders = async () => {
    axios
      .get(`https://api.jumpersksa.online/users/user_data`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setId(res?.data?.data?._id);
        axios
          .get(
            `https://api.jumpersksa.online/orders/user_orders?page=${page}&limit=${limit}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: localStorage.getItem("token"),
              },
            }
          )
          .then((res) => {
            console.log(res);
            setLoading(false);
            // res.data.data[0].status = "rejected";
            setOrders(res?.data?.data);
            // setNumOfPage(res?.data?.paginationResult?.numberOfPages);
            if (res?.data?.paginationResult?.numberOfPages > 5) {
              setNumOfPage(5);
            } else {
              setNumOfPage(res?.data?.paginationResult?.numberOfPages);
            }
            setDocumentsCounts(res?.data?.documentsCounts);
            setLastPage(res?.data?.paginationResult?.numberOfPages);
            setPages(res?.data?.documentsCounts);

            setResults(res?.data?.results);
          })
          .catch((err) => {
            console.error(err);
          });
      })
      .catch((err) => {
        console.error(err);
      });
  };
  let pagesArr = [];
  // useEffect(() => {
  //   if (numOfPage > 5) {
  //     setNumOfPage(5);
  //   }
  // }, [numOfPage]);

  for (let i = 1; i <= numOfPage; i++) {
    pagesArr.push(i);
  }
  const handleNext = () => {
    setPage(page + 1);
  };
  const handlePrev = () => {
    setPage(page - 1);
  };
  const navigate = useNavigate();
  const handleCheck = () => {
    if (userInfo && userInfo.city) {
      navigate("/orderinfo");
      // navigate("/completeProfileClient");
    } else {
      navigate("/completeProfileClient");
      toast.warning("من فضلك قم بإكمال ملفك أولا");
    }
  };
  const getOrderStatus = (status) => {
    switch (status) {
      case "pending":
        return "pending";
      case "inReview":
        return t("inReview");
      case "drafted":
        return t("Drafted");
      case "completed":
        return t("Completed");
      case "started":
        return t("Started");
      default:
        return "pending";
    }
  };
  const [loading, setLoading] = useState(true);
  return loading ? (
    <Loader />
  ) : (
    <>
      {orders.length > 0 ? (
        <>
          <Breadcrumb pageName={t("Orders")} />

          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto text-center">
                <thead>
                  <tr className="bg-gray-2 dark:bg-meta-4 ">
                    <th className="min-w-[50px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("No.")}
                    </th>
                    {data === "jumper" && (
                      <>
                        <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                          {t("Client name")}
                        </th>
                      </>
                    )}
                    {/* <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Start Date")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("End Date")}
                    </th> */}
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Total CostClient")}
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("Order Status")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white ">
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {orders?.map((e, index) => (
                    <tr key={e?._id}>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {index + 1}
                      </td>
                      {data === "jumper" && (
                        <>
                          <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                            {e?.client?.firstName} {e?.client?.lastName}
                          </td>
                        </>
                      )}
                      {/* <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {e.startDate
                          ? new Date(e?.startDate).toLocaleString()
                          : ""}
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        {e.endDate ? new Date(e?.endDate).toLocaleString() : ""}
                      </td> */}
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11 ">
                        <span>
                          {data === "client" ? (
                            <span>
                              {e?.totalPrice}({t("SAR")})
                            </span>
                          ) : (
                            <span>
                              {(
                                e?.timeAndCost?.jumperPrice -
                                e?.timeAndCost?.jumperPrice *
                                  (e?.siteRatio / 100)
                              ).toFixed(2)}
                              {t("SAR")}
                            </span>
                          )}
                        </span>
                      </td>
                      <td className="py-5 px-4">
                        <p
                          className={`inline-flex rounded-full  bg-opacity-10 py-1 px-3 text-sm font-medium
                   ${
                     e?.orderStatus === "pending"
                       ? "bg-warning text-warning"
                       : e.orderStatus === "drafted"
                       ? "bg-danger text-danger"
                       : "bg-success text-success"
                   }
                  `}
                        >
                          {getOrderStatus(e?.orderStatus) === "pending"
                            ? e?.isPaid
                              ? t("waiting to start")
                              : t("waiting to pay")
                            : getOrderStatus(e?.orderStatus)}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center justify-center space-x-3.5">
                          <Link to={`/order/${e?._id}`}>
                            <button className="hover:text-primary">
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                  fill=""
                                />
                                <path
                                  d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                  fill=""
                                />
                              </svg>
                            </button>
                          </Link>
                          {/* <button className="hover:text-primary">
                        <svg
                          className="fill-current"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.7535 2.47502H11.5879V1.9969C11.5879 1.15315 10.9129 0.478149 10.0691 0.478149H7.90352C7.05977 0.478149 6.38477 1.15315 6.38477 1.9969V2.47502H4.21914C3.40352 2.47502 2.72852 3.15002 2.72852 3.96565V4.8094C2.72852 5.42815 3.09414 5.9344 3.62852 6.1594L4.07852 15.4688C4.13477 16.6219 5.09102 17.5219 6.24414 17.5219H11.7004C12.8535 17.5219 13.8098 16.6219 13.866 15.4688L14.3441 6.13127C14.8785 5.90627 15.2441 5.3719 15.2441 4.78127V3.93752C15.2441 3.15002 14.5691 2.47502 13.7535 2.47502ZM7.67852 1.9969C7.67852 1.85627 7.79102 1.74377 7.93164 1.74377H10.0973C10.2379 1.74377 10.3504 1.85627 10.3504 1.9969V2.47502H7.70664V1.9969H7.67852ZM4.02227 3.96565C4.02227 3.85315 4.10664 3.74065 4.24727 3.74065H13.7535C13.866 3.74065 13.9785 3.82502 13.9785 3.96565V4.8094C13.9785 4.9219 13.8941 5.0344 13.7535 5.0344H4.24727C4.13477 5.0344 4.02227 4.95002 4.02227 4.8094V3.96565ZM11.7285 16.2563H6.27227C5.79414 16.2563 5.40039 15.8906 5.37227 15.3844L4.95039 6.2719H13.0785L12.6566 15.3844C12.6004 15.8625 12.2066 16.2563 11.7285 16.2563Z"
                            fill=""
                          />
                          <path
                            d="M9.00039 9.11255C8.66289 9.11255 8.35352 9.3938 8.35352 9.75942V13.3313C8.35352 13.6688 8.63477 13.9782 9.00039 13.9782C9.33789 13.9782 9.64727 13.6969 9.64727 13.3313V9.75942C9.64727 9.3938 9.33789 9.11255 9.00039 9.11255Z"
                            fill=""
                          />
                          <path
                            d="M11.2502 9.67504C10.8846 9.64692 10.6033 9.90004 10.5752 10.2657L10.4064 12.7407C10.3783 13.0782 10.6314 13.3875 10.9971 13.4157C11.0252 13.4157 11.0252 13.4157 11.0533 13.4157C11.3908 13.4157 11.6721 13.1625 11.6721 12.825L11.8408 10.35C11.8408 9.98442 11.5877 9.70317 11.2502 9.67504Z"
                            fill=""
                          />
                          <path
                            d="M6.72245 9.67504C6.38495 9.70317 6.1037 10.0125 6.13182 10.35L6.3287 12.825C6.35683 13.1625 6.63808 13.4157 6.94745 13.4157C6.97558 13.4157 6.97558 13.4157 7.0037 13.4157C7.3412 13.3875 7.62245 13.0782 7.59433 12.7407L7.39745 10.2657C7.39745 9.90004 7.08808 9.64692 6.72245 9.67504Z"
                            fill=""
                          />
                        </svg>
                      </button>
                      <Link to="/chat">
                        <button className="hover:text-primary">
                          <AiOutlineMessage size={20} />
                        </button>
                      </Link> */}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>

          <div class="flex lg:flex-row justify-between mt-3 items-center">
            <nav
              aria-label="Pagination"
              class="flex justify-center items-center text-gray-600 mt-8 lg:mt-0"
            >
              {/* <button
                onClick={handlePrev}
                disabled={page === 1}
                className="p-2 mr-4 rounded hover:bg-gray-100"
              >
                {i18n.language === "ar" ? (
                  <FaGreaterThan size={18} />
                ) : (
                  <FaLessThan size={18} />
                )}
              </button>
              <p>
                {t("Page")} {page}
              </p>
              <button
                onClick={handleNext}
                disabled={page === numOfPage}
                class="p-2 ml-4 rounded hover:bg-gray-100"
              >
                {i18n.language === "ar" ? (
                  <FaLessThan size={18} />
                ) : (
                  <FaGreaterThan size={18} />
                )}
              </button> */}
              <div
                className="flex justify-around items-center bg-gradient-to-r from-[#000]  to-[#2e414ade] text-white rounded-3xl p-2"
                dir="ltr"
              >
                <button
                  onClick={handlePrev}
                  disabled={page === 1}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === 1 ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === 1 ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrPrevious size={15} />
                  </div>
                </button>
                {pagesArr.map((page) => (
                  <div
                    onClick={() => setPage(page)}
                    className="flex mx-1 justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                  >
                    <div
                      className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                        page === page ? "bg-white text-black" : ""
                      }`}
                    >
                      {page}
                    </div>
                  </div>
                ))}
                {lastPage > 5 ? (
                  <>
                    <span
                      className="cursor-pointer"
                      // onClick={setNumOfPage(lastPage)}
                    >
                      ...
                    </span>

                    <div
                      onClick={() => setPage(lastPage)}
                      className="flex mx-1 justify-center items-center bg-[#145c0dd8] p-1 rounded-full cursor-pointer transition-all ease-in duration-75 hover:border-2 border-solid border-white"
                    >
                      <div
                        className={`pages border-2 border-solid border-white rounded-full justify-center items-center flex w-6  cursor-pointer ${
                          page === page ? "bg-white text-black" : ""
                        }`}
                      >
                        {lastPage}
                      </div>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <button
                  onClick={handleNext}
                  disabled={page === lastPage}
                  className={`flex justify-center items-center bg-[#145c0dd8] p-1 border-2 border-solid border-white rounded-full cursor-pointer ${
                    page === lastPage ? "bg-opacity-50 cursor-default" : ""
                  } `}
                >
                  <div
                    className={`prev border-lg-primary bg-white text-meta-3 rounded-full p-2 ${
                      page === lastPage ? "bg-opacity-50 cursor-default" : ""
                    }`}
                  >
                    <GrNext size={15} />
                  </div>
                </button>
              </div>
            </nav>
            <p>
              {t("showing")} <span className="text-[#60a9bf]">{results}</span>{" "}
              {t("pages")} {t("of")}{" "}
              <span className="text-[#60a9bf]">{pages}</span>
            </p>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center my-5">
            <div className="m-auto w-4/5 flex justify-center">
              <img
                src={noOffers}
                alt="No Offers Available"
                className="w-50 h-50 "
              />
            </div>
            <div className="text-center">
              <p className="font-bold text-lg">
                {t("Sorry! you don't have Orders Yet")}
              </p>
              {data === "client" && (
                <>
                  <button
                    // to={"/orderinfo"}
                    onClick={handleCheck}
                    className="flex cursor-pointer w-full items-center justify-center gap-2 rounded bg-primary py-1 px-2 text-sm font-medium text-white hover:bg-opacity-80 xsm:px-4"
                  >
                    {t("create Order")}
                  </button>
                </>
              )}
              {data === "jumper" && <p>{t("complete your profile,")}</p>}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Orders;
