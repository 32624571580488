import { useEffect } from "react";
import CardFour from "../../components/CardFour.jsx";
import CardOne from "../../components/CardOne.jsx";
import CardThree from "../../components/CardThree.jsx";
import CardTwo from "../../components/CardTwo.jsx";
import ChartOne from "../../components/ChartOne.jsx";
import ChartThree from "../../components/ChartThree.jsx";
import ChartTwo from "../../components/ChartTwo.jsx";
import { fetchUser, setLoader } from "../../Store/Actions/userActions.js";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import Loader from "../../common/Loader/index.jsx";

const GeneralStatistics = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const role = localStorage.getItem("role");
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchUser(token, i18n.language));
    } else if (window.location.pathname === "/auth/privacypolicy") {
      navigate("/auth/privacypolicy"); // Redirect to the desired path
    } else {
      // dispatch(setLoader(false));
      navigate("/auth/signin");
    }
    // Check if the current path is "/auth/privacypolicy"
  }, [i18n.language]);
  return (
     <>
      {role === "jumper" && (
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
          <CardOne />
          <CardTwo />
          <CardThree />
          <CardFour />
        </div>
      )}

      <div className="mt-4 grid grid-cols-12 gap-4 md:mt-6 md:gap-6 2xl:mt-7.5 2xl:gap-7.5">
        <ChartOne />
        <ChartTwo />
        {/* <ChartThree /> */}
      </div>
    </>
  )
};

export default GeneralStatistics;
