import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import userSix from "../../images/user/default.png";
import "./Orders.css";
import { useTranslation } from "react-i18next";
const JumpersStatus = ({ socket }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [jumper, setJumper] = useState([]);

  const getJumpersStatus = () => {
    axios
      .get(`https://api.jumpersksa.online/offers?order=${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        setJumper(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getJumpersStatus();

    socket.on("update order recieved", () => {
      getJumpersStatus();
    });
  }, []);
  const getOrderStatus = (status) => {
    switch (status) {
      case "pending":
        return t("Pending");
      case "accepted":
        return t("Accepted");
      case "rejected":
        return t("Rejected");
      default:
        return "pending";
    }
  };
  return (
    <div>
      <table className="w-full table-auto">
        <thead>
          <tr className="bg-gray-2 dark:bg-meta-4 text-center">
            <th className="min-w-[50px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
              {t("No.")}
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Jumper Image")}
            </th>
            <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
              {t("Jumber Name")}
            </th>
            <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
              {t("status")}
            </th>
          </tr>
        </thead>
        <tbody>
          {jumper?.map((e, index) => (
            <tr key={e.jumper?._id} className="trInJS">
              <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                {index + 1}
              </td>
              <td className="border-b flex justify-center border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                <img
                  src={
                    e.jumper?.image
                      ? `https://api.jumpersksa.online/${e.jumper?.image}`
                      : userSix
                  }
                  alt="profile"
                  width={100}
                  height={100}
                  className="rounded-3xl"
                />
              </td>

              <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                {e?.jumper?.firstName} {e?.jumper?.lastName}
              </td>

              <td className="py-5 px-4">
                <p
                  className={`inline-flex rounded-full  bg-opacity-10 py-1 px-3 text-sm font-medium  
                   ${
                     e.status === "pending"
                       ? "bg-warning text-warning"
                       : e.orderStatus === "drafted"
                       ? "bg-danger text-danger"
                       : "bg-success text-success"
                   }
                  `}
                >
                  {getOrderStatus(e.status)}
                </p>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default JumpersStatus;
