import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb";
import { useTranslation } from "react-i18next";
import Loader from "../../common/Loader";
import { useDispatch, useSelector } from "react-redux";
import noOffers from "../../images/product/SEO.webp";
import { Link } from "react-router-dom";
import userDefault from "../../images/user/default.png";

import { use } from "i18next";
import axios from "axios";
import { toast } from "react-toastify";
import { AiFillHeart, AiOutlineHeart, AiTwotoneStar } from "react-icons/ai";
import { setUser } from "../../Store/Actions/userActions";

const FavList = () => {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [favList, setFavList] = useState([]);
  const [favJumper, setFavJumper] = useState(true);
  const { userInfo } = useSelector((state) => state.user);
  useEffect(() => {
    if (userInfo) {
      setLoading(false);
      setFavList(userInfo?.favList);
    }
  }, [userInfo]);
  const addToFav = (jumperID) => {
    axios
      .post(
        `https://api.jumpersksa.online/users/add_fav`,
        {
          jumper: jumperID,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        dispatch(setUser(res.data.data));
        toast.success(t("jumper added succefully to favourites"));
        setFavList([...favList, jumperID]);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const removeFromFav = (jumperID) => {
    axios
      .delete(`https://api.jumpersksa.online/users/remove_fav/${jumperID}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        console.log(res);
        dispatch(setUser(res.data.data));
        toast.success(t("jumper removed succefully to favourites"));
        const newFavList = favList.filter((f) => {
          return f !== jumperID;
        });
        setFavList(newFavList);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return loading ? (
    <Loader />
  ) : (
    <>
      {favList.length > 0 ? (
        <>
          <Breadcrumb pageName={t("Favourite List")} />
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="text-center">
                  <tr className="bg-gray-2 text-center dark:bg-meta-4">
                    <th className="min-w-[50px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("NO.")}
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Jumper")}
                    </th>
                    <th className="min-w-[100px] py-4 px-4 font-medium text-black dark:text-white xl:pl-11">
                      {t("Gender")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Age")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("City")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Nationality")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Rating")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("is Favourite ?")}
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody className="text-center">
                  {favList?.map((fav, index) => (
                    <tr key={fav?._id} className="text-center">
                      <td className="border-b border-[#eee] py-5 px-4 pl-9 dark:border-strokedark xl:pl-11">
                        <p className="text-black dark:text-white">
                          {index + 1}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          <div className="JumperImg flex justify-center">
                            <img
                              src={
                                fav?.image
                                  ? `https://api.jumpersksa.online/${fav?.image}`
                                  : userDefault
                              }
                              alt="j-img"
                              className="rounded-3xl w-20"
                            />
                          </div>
                          <div className="JumperName">
                            <p className="text-black dark:text-white">
                              {fav?.firstName} {fav?.lastName}
                            </p>
                          </div>
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black text-center dark:text-white">
                          {fav?.gender}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          {fav?.age}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          {fav?.city?.name}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          {fav?.nationality}
                        </p>
                      </td>
                      <td className="border-b border-[rgb(238,238,238)] py-5 px-4 dark:border-strokedark">
                        <p className="text-black capitalize dark:text-white">
                          <span
                            className="flex items-center justify-center mx-1"
                            title={t("Your rating")}
                          >
                            ({fav?.rating?.toFixed(1)}
                            <AiTwotoneStar
                              className="inline ml-1 starRateInProfile"
                              fill="orange"
                            />
                            )
                          </span>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark text-center  ">
                        <div className="flex justify-center items-center">
                          {favJumper ? (
                            <AiFillHeart
                              color="crimson"
                              fontSize={25}
                              onClick={() => {
                                removeFromFav(fav?._id);
                                setFavJumper(false);
                              }}
                              className="cursor-pointer"
                            />
                          ) : (
                            <AiOutlineHeart
                              fontSize={25}
                              onClick={() => addToFav(fav?._id)}
                              className="cursor-pointer"
                            />
                          )}
                        </div>
                      </td>

                      <td className="border-b  border-[#eee] py-5 px-4 dark:border-strokedark">
                        <div className="flex items-center justify-center space-x-3.5">
                          <Link
                            to={`/favdetails/${fav?._id}`}
                            className="hover:text-primary"
                          >
                            <svg
                              className="fill-current"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                fill=""
                              />
                              <path
                                d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                fill=""
                              />
                            </svg>
                          </Link>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col justify-center items-center my-5">
            <div className="m-auto w-4/5 flex justify-center">
              <img
                src={noOffers}
                alt="No Offers Available"
                className="w-50 h-50"
              />
            </div>
            <div className="text-center">
              <p className="font-bold text-lg">
                {t("Sorry! you don't have Favourite jumpers Yet")}
              </p>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default FavList;
