import React from "react";
import "./SetReview.css";
import { useState } from "react";
import axios from "axios";
import { AiOutlineStar, AiFillStar } from "react-icons/ai";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { t } from "i18next";

const SetReview = (props) => {
  const { orderId, jumperId, jumperRating } = props;
  const [rating, setrating] = useState(jumperRating);
  const [hoverStar, setHoverStar] = useState(undefined);
  console.log(orderId, jumperId);
  const SetReview = (r) => {
    axios
      .put(
        `https://api.jumpersksa.online/orders/review/${orderId}`,
        {
          jumperId: jumperId,
          rating: r,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      )
      .then((res) => {
        console.log(res);
        toast.success(t("Rating added succefully"));
        setrating(r);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <div className="review-content">
      <div className="stars">
        {Array(5)
          .fill()
          .map((_, index) =>
            rating >= index + 1 || hoverStar >= index + 1 ? (
              <AiFillStar
                className="AiFill cursor-pointer"
                onMouseOver={() => !rating && setHoverStar(index + 1)}
                onMouseLeave={() => setHoverStar(undefined)}
                onClick={() => {
                  SetReview(index + 1);
                }}
              />
            ) : (
              <AiOutlineStar
                onMouseOver={() => !rating && setHoverStar(index + 1)}
                onMouseLeave={() => setHoverStar(undefined)}
                onClick={() => {
                  SetReview(index + 1);
                }}
                className="cursor-pointer"
              />
            )
          )}
      </div>
    </div>
  );
};

export default SetReview;
