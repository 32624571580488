import React from "react";
import Breadcrumb from "../../src/components/Breadcrumb";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosInstance } from "../axiosInst";
import { useEffect } from "react";
import "./style.css";
import { useTranslation } from "react-i18next";
import axios from "axios";
const PrivacyModal = ({ closeModal, socket, updateOrder }) => {
  const [order, setOrder] = useState();
  const { t, i18n } = useTranslation();
  const [interfacee, setInterface] = useState();

  const sendNotoficationOfPaid = (jumpers) => {
    const jumpersIds = jumpers.map((j) => j.jumper._id);
    const notification = {
      users: jumpersIds,
      type: "order",
      title_en: "Payment Success",
      desc_en: `Payment success for order check it out`,
      title_ar: "تم الدفع بنجاح",
      desc_ar: "تم الدفع بنجاح تحقق منها عن طريق الرابط",
      link: order._id,
      createdAt: Date.now(),
    };
    socket.emit("new notfication", notification);
    socket.emit("update order", jumpersIds);
  };

  useEffect(() => {
    axios
      .get(
        `https://api.jumpersksa.online/interfaces/65223344b614e8df4be32e1a`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
            lang: i18n.language,
          },
        }
      )
      .then((res) => {
        console.log(res);
        setInterface(res?.data?.interface);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [i18n.language]);
  const { id } = useParams();
  const handlePay = async () => {
    if (order?.jumpers?.length) {
      if (order.isPaid) {
        toast.warning(t("Order is already paid"));
      } else {
        try {
          const result = await axiosInstance.get(
            `/orders/pay_order_url/${id}`,
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );
          console.log(result.data.URL);
          // const win = window.open(result.data.URL,'width=800,height=600');
          // Calculate 80% of the screen width
          const width = Math.floor(window.screen.width * 0.8);
          const height = 550 //Math.floor(window.screen.height * 0.8);

          // Calculate the center position
          const left = window.innerWidth / 2 - width / 2;
          const top = window.innerHeight / 2 - height / 2;

          // Open a new window with specified width and height
          const newWindow = window.open(
            "",
            "_blank",
            "width=" + width + ", height="+height+", top="+top+", left="+left
          );

          // Optional: Set the location of the new window to a specific URL
          newWindow.location.href = result.data.URL;

          const timer = setInterval(function () {
            if (newWindow.closed) {
              clearInterval(timer);
              updateOrder();
              sendNotoficationOfPaid(order.jumpers);
            }
          }, 100);
        } catch (error) {
          console.log(error);
          toast.error(
            `you cant pay order while status of order is ${order?.orderStatus}`
          );
        }
      }
    } else {
      toast.error(t("Waiting for jumpers to accept offers"));
    }
  };
  const getOrder = async () => {
    if (id) {
      try {
        const res = await axiosInstance.get(`/orders/user_order/${id}`, {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        });
        console.log(res?.data?.data);
        setOrder(res?.data?.data);
      } catch (error) {
        if (error?.response?.data?.err) {
          toast.error(error?.response?.data?.err);
        } else {
          toast.error(error?.message);
        }
      }
    }
  };

  useEffect(() => {
    getOrder();
  }, []);
  return (
    <div
      className="modal-container-two"
      onClick={(e) => {
        if (e.target.className === "modal-container") closeModal();
      }}
    >
      <div className="relative w-full modal rounded-sm border border-stroke bg-white shadow-default dark:border-strokedark dark:bg-boxdark overflow-auto ">
        <div className=" border-stroke py-4 px-7">
          <div className="w-full flex justify-end">
            <strong
              className="text-xl align-center cursor-pointer "
              onClick={closeModal}
            >
              &times;
            </strong>
          </div>{" "}
          <p className="text-2xl m-3">{t("Privacy policy")}</p>
          <div className="mb-5.5 rounded border overflow-hidden border-stroke bg-gray py-3 px-4.5 text-black focus:border-primary focus-visible:outline-none dark:border-strokedark dark:bg-meta-4 dark:text-white dark:focus:border-primary">
            <p className="overflow-hidden">
              {/* Lorem ipsum dolor sit amet consectetur adipiscing elit neque
              dignissim, eget habitant euismod congue morbi volutpat turpis nisi
              litora porta, orci tempus magna mauris penatibus per massa
              cubilia. Odio purus elementum iaculis convallis curae scelerisque
              sociosqu massa lacus */}
              <div
                dangerouslySetInnerHTML={{
                  __html: interfacee?.desc,
                }}
                className="flex flex-col justify-start items-start gap-x-1.5 text-blue-600 decoration-2 font-medium"
              ></div>
            </p>
          </div>
          <div className="flex justify-between items-end gap-4.5 bottom-4 right-4">
            <button
              className="flex justify-center rounded bgteal py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={() => {
                handlePay();
                closeModal();
              }}
            >
              {t("Agree & Pay")}
            </button>
            <button
              className="flex justify-center rounded bg-danger py-2 px-6 font-medium text-gray hover:shadow-1"
              type="button"
              onClick={closeModal}
            >
              {t("Cancel")}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyModal;
