import { Link, useNavigate } from "react-router-dom";
import OrderingSteps from "../../components/OrderingSteps";
import { useEffect, useState } from "react";
import { axiosInstance } from "../../axiosInst";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setOrder } from "../../Store/Actions/orderActions";
import { IoMdAddCircle } from "react-icons/io";
import defaultImg from "../../images/user/default.png";
import { BiUndo } from "react-icons/bi";
import "./Orders.css";
import ContractModalClient from "../../components/ContractModalClient";
import { useTranslation } from "react-i18next";
import { AddJumper, rmvJumper } from "../../Store/Actions/orderJumpersActions";
import { FaCheck } from "react-icons/fa6";

import { AiTwotoneStar } from "react-icons/ai";

const ClientSelectJumbers = () => {
  const { userInfo } = useSelector((state) => state.user);
  const [favJumpers, setFavJumpers] = useState([]);
  useEffect(() => {
    if (userInfo?.favList?.length > 0) {
      setFavJumpers(userInfo?.favList);
    }
  }, [userInfo]);

  const { t } = useTranslation();
  const [jumpers, setJumpers] = useState([]);
  const order = useSelector((state) => state.order);
  const selectedJumpers = useSelector((state) => state.orderJumpers);
  const [notFound, setNotFound] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const fetchJumpers = async (filterStr) => {
    const result = await axiosInstance.get(`/jumperServices?${filterStr}`);
    console.log(result.data.data);
    if (result.data.data.length) {
      setJumpers(result.data.data);
    } else {
      setNotFound(t("No Jumpers Matching Requirements"));
    }
  };
  const addJumpers = (j) => {
    console.log("adddd",j);
    dispatch(AddJumper(j));
  };
  const undoAddJumpers = (j) => {
    dispatch(rmvJumper(j));
  };
  const [showContractModal, setShowContractModal] = useState(false);

  function handleSave() {
    if (selectedJumpers.length) {
      navigate("/orderreviewandpublication");
      dispatch(
        setOrder({
          jumpers: selectedJumpers,
          jumpersOfferNumber: selectedJumpers.length,
        })
      );
      toast.success(t("Jumpers Added Successfully"));
    } else {
      console.log("errrrrrrrrrrrrr");
      toast.error(t("You Should Select Jumpers"));
    }
  }

  useEffect(() => {
    const {
      service,
      orderInfo: {
        city,
        JumpersAgeMin,
        JumpersAgeMax,
        JumpersNationality,
        JumpersGender,
        JumpersSkills,
      },
    } = order;

    let str = `service=${service}&city=${city}&age[gte]=${JumpersAgeMin}&age[lte]=${JumpersAgeMax}`;

    if (JumpersNationality !== "all") {
      str += `&nationality=${JumpersNationality}`;
    }

    if (JumpersGender !== "all") {
      str += `&gender=${JumpersGender}`;
    }

    if (order?.orderInfo?.JumpersSkills) {
      const idsArray = order?.orderInfo?.JumpersSkills;
      str += `&skills=${idsArray.join(",")}`;
    }

    console.log(str);
    fetchJumpers(str);
  }, [order]);
  const [showUndo, setShowUndo] = useState(false);
  const [idd, setIdd] = useState(null);
  const fun = (id) => {
    addJumpers(id);
  };
  function changeNationalityName(nationality) {
    switch (nationality) {
      case "saudi":
        return t("Saudi");
      case "other":
        return t("Other");
      default:
        return t("Not selected");
    }
  }
  function changeGenderName(gender) {
    switch (gender) {
      case "male":
        return t("Male");
      case "female":
        return t("Female");
      default:
        return t("Not selected");
    }
  }
  return (
    <>
      <OrderingSteps step1 step2 step3 />
      {jumpers.length ? (
        <>
          <div className="flex flex-col md:flex-row md:justify-between md:items-end">
            <div className="flex justify-between contBlocks gap-1 mb-5">
              <div
                className="rounded-sm border border-stroke bg-white ajjs shadow-default dark:border-strokedark dark:bg-boxdark"
                style={{ width: "fit-content" }}
              >
                <div className="my-2 flex items-end justify-between">
                  <div>
                    <h4 className="text-title-md font-bold text-black text-center dark:text-white">
                      {selectedJumpers.length}
                    </h4>
                    <span className="text-sm font-medium">
                      {t("Jumpers Count")}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="rounded-sm border border-stroke bg-white ajjs shadow-default dark:border-strokedark dark:bg-boxdark"
                style={{ width: "fit-content" }}
              >
                <div className="my-2 flex items-end justify-between">
                  <div>
                    <h4 className="text-title-md font-bold text-black text-center dark:text-white">
                      {order.timeAndCost.jumperPrice}
                    </h4>
                    <span className="text-sm font-medium">
                      {t("Jumper Price")}
                    </span>
                  </div>
                </div>
              </div>
              <div
                className="rounded-sm border border-stroke bg-white ajjs shadow-default dark:border-strokedark dark:bg-boxdark"
                style={{ width: "fit-content" }}
              >
                <div className="my-2 flex items-end justify-between">
                  <div>
                    <h4 className="text-title-md font-bold text-black text-center dark:text-white">
                      {order.timeAndCost.jumperPrice * selectedJumpers.length}
                    </h4>
                    <span className="text-sm font-medium">
                      {t("Total Price")}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <button
                className="items-center flex mb-4 px-5 py-3 rounded-md bgteal text-center font-medium text-white hover:bg-opacity-90"
                onClick={handleSave}
              >
                {t("Save")}
              </button>
            </div>
          </div>
          <div className="rounded-sm border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
            <div className="max-w-full overflow-x-auto">
              <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2  dark:bg-meta-4 text-center">
                    <th className="min-w-[50px] py-4 px-1 font-medium text-black dark:text-white xl:pl-11">
                      {t("No.")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Jumber Name")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Age")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Nationality")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("City")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Gender")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Rating")}
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("service")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                {showContractModal && (
                  <ContractModalClient
                    closeModal={() => setShowContractModal(false)}
                    onSubmit={() => {
                      setShowContractModal(false);
                    }}
                    defaultValue
                    addJumpers={() => {
                      addJumpers(idd);
                    }}
                    id={idd}
                    serviceName={order?.serviceName}
                    jumperPrice={order?.timeAndCost?.jumperPrice}
                  />
                )}
                <tbody className="tableSelectJumpers">
                  {jumpers?.map((j, i) => (
                    <tr
                      style={{
                        backgroundColor: selectedJumpers.includes(
                          j?.jumper?._id
                        )
                          ? "rgba(0, 128, 128, 0.516)"
                          : "",
                      }}
                      className="text-center"
                    >
                      <td className="border-b border-[#eee] py-5 px-1 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {i + 1}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark flex flex-col items-center justify-center">
                        <p className="text-black dark:text-white">
                          <img
                            src={
                              j?.jumper?.image
                                ? `https://api.jumpersksa.online/${j?.jumper?.image}`
                                : defaultImg
                            }
                            alt="jumper-cover"
                            width={80}
                            height={80}
                            className="rounded-full"
                          />
                        </p>
                        <p className="text-black dark:text-white">
                          {`${j.jumper?.firstName} ${j.jumper?.lastName}`}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">{j?.age}</p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {changeNationalityName(j?.nationality)}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {j.city?.name}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {changeGenderName(j?.gender)}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          <span
                            className="flex items-center justify-center mx-1"
                            title={t("Your rating")}
                          >
                            ({j?.jumper?.rating?.toFixed(1)}
                            <AiTwotoneStar
                              className="inline ml-1 starRateInProfile"
                              fill="orange"
                            />
                            )
                          </span>
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="inline-flex m-1 rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                          {j?.service?.name}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark ">
                        <div className="flex m-3 justify-between">
                          {selectedJumpers.includes(j?.jumper?._id) ? (
                            <button
                              onClick={() => {
                                undoAddJumpers(j?.jumper?._id);
                              }}
                              className="inline-flex items-center justify-center bg-stroke rounded-full  px-2 text-danger py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90"
                            >
                              <BiUndo />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setShowContractModal(true);
                                setIdd(j?.jumper?._id);
                              }}
                              className="inline-flex items-center justify-center text-success bg-success bg-opacity-50 rounded-full  px-2 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-30"
                            >
                              <FaCheck />
                            </button>
                          )}

                          <button className="inline-flex items-center justify-center rounded-md  px-3 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90">
                            <Link
                              to={`jumperProfile/${j?._id}`}
                              rel="noreferrer"
                            >
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                  fill=""
                                />
                                <path
                                  d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                  fill=""
                                />
                              </svg>
                            </Link>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>

              {/* <table className="w-full table-auto">
                <thead>
                  <tr className="bg-gray-2  dark:bg-meta-4 text-center">
                    <th className="min-w-[50px] py-4 px-1 font-medium text-black dark:text-white xl:pl-11">
                      {t("No.")}
                    </th>
                    <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Jumber Name")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Age")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Nationality")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("City")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Gender")}
                    </th>
                    <th className="py-4 px-4 font-medium text-black dark:text-white">
                      {t("service")}
                    </th>
                    <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                      {t("Actions")}
                    </th>
                  </tr>
                </thead>
                {showContractModal && (
                  <ContractModalClient
                    closeModal={() => setShowContractModal(false)}
                    onSubmit={() => {
                      setShowContractModal(false);
                    }}
                    defaultValue
                    addJumpers={() => {
                      addJumpers(idd);
                    }}
                    id={idd}
                    serviceName={order?.serviceName}
                    jumperPrice={order?.timeAndCost?.jumperPrice}
                  />
                )}
                <tbody className="tableSelectJumpers">
                  {jumpers?.map((j, i) => (
                    <tr
                      style={{
                        backgroundColor: selectedJumpers.includes(
                          j?.jumper?._id
                        )
                          ? "rgba(0, 128, 128, 0.516)"
                          : "",
                      }}
                      className="text-center"
                    >
                      <td className="border-b border-[#eee] py-5 px-1 pl-9 dark:border-strokedark xl:pl-11">
                        <h5 className="font-medium text-black dark:text-white">
                          {i + 1}
                        </h5>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark flex flex-col items-center justify-center">
                        <p className="text-black dark:text-white">
                          <img
                            src={
                              j?.jumper?.image
                                ? `https://api.jumpersksa.online/${j?.jumper?.image}`
                                : defaultImg
                            }
                            alt="jumper-cover"
                            width={80}
                            height={80}
                            className="rounded-full"
                          />
                        </p>
                        <p className="text-black dark:text-white">
                          {`${j.jumper?.firstName} ${j.jumper?.lastName}`}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">{j?.age}</p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {j?.nationality}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {j.city?.name}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="text-black dark:text-white">
                          {j?.gender}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                        <p className="inline-flex m-1 rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                          {j?.service?.name}
                        </p>
                      </td>
                      <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark ">
                        <div className="flex m-3 justify-between">
                          {selectedJumpers.includes(j?.jumper?._id) ? (
                            <button
                              onClick={() => {
                                undoAddJumpers(j?.jumper?._id);
                              }}
                              className="inline-flex items-center justify-center rounded-md  px-3 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90"
                            >
                              <BiUndo />
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setShowContractModal(true);
                                setIdd(j?.jumper?._id);
                              }}
                              className="inline-flex items-center justify-center rounded-md  px-3 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90"
                            >
                              <IoMdAddCircle />
                            </button>
                          )}

                          <button className="inline-flex items-center justify-center rounded-md  px-3 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90">
                            <Link
                              to={`jumperProfile/${j?._id}`}
                              rel="noreferrer"
                            >
                              <svg
                                className="fill-current"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                  fill=""
                                />
                                <path
                                  d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                  fill=""
                                />
                              </svg>
                            </Link>
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table> */}
            </div>
          </div>
          {userInfo?.favList?.length > 0 ? (
            <div className="rounded-sm my-4 border border-stroke bg-white px-5 pt-6 pb-2.5 shadow-default dark:border-strokedark dark:bg-boxdark sm:px-7.5 xl:pb-1">
              <p className="text-center mb-4 font-semibold text-2xl">
                {t("Your Favourite Jumpers")}
              </p>
              <div className="max-w-full overflow-x-auto">
                <table className="w-full table-auto">
                  <thead>
                    <tr className="bg-gray-2  dark:bg-meta-4 text-center">
                      <th className="min-w-[50px] py-4 px-1 font-medium text-black dark:text-white xl:pl-11">
                        {t("No.")}
                      </th>
                      <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Jumber Name")}
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Age")}
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Nationality")}
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("City")}
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Gender")}
                      </th>
                      <th className="min-w-[150px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Rating")}
                      </th>
                      <th className="py-4 px-4 font-medium text-black dark:text-white">
                        {t("service")}
                      </th>
                      <th className="min-w-[120px] py-4 px-4 font-medium text-black dark:text-white">
                        {t("Actions")}
                      </th>
                    </tr>
                  </thead>
                  {showContractModal && (
                    <ContractModalClient
                      closeModal={() => setShowContractModal(false)}
                      onSubmit={() => {
                        setShowContractModal(false);
                      }}
                      defaultValue
                      addJumpers={() => {
                        addJumpers(idd);
                      }}
                      id={idd}
                      serviceName={order?.serviceName}
                      jumperPrice={order?.timeAndCost?.jumperPrice}
                    />
                  )}
                  <tbody className="tableSelectJumpers">
                    {favJumpers?.map((j, i) => (
                      <tr
                        style={{
                          backgroundColor: selectedJumpers.includes(j?._id)
                            ? "rgba(0, 128, 128, 0.516)"
                            : "",
                        }}
                        className="text-center"
                      >
                        <td className="border-b border-[#eee] py-5 px-1 pl-9 dark:border-strokedark xl:pl-11">
                          <h5 className="font-medium text-black dark:text-white">
                            {i + 1}
                          </h5>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark flex flex-col items-center justify-center">
                          <p className="text-black dark:text-white">
                            <img
                              src={
                                j?.image
                                  ? `https://api.jumpersksa.online/${j?.image}`
                                  : defaultImg
                              }
                              alt="jumper-cover"
                              width={80}
                              height={80}
                              className="rounded-full"
                            />
                          </p>
                          <p className="text-black dark:text-white">
                            {`${j?.firstName} ${j?.lastName}`}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="text-black dark:text-white">{j?.age}</p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="text-black dark:text-white">
                            {changeNationalityName(j?.nationality)}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="text-black dark:text-white">
                            {j.city?.name}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="text-black dark:text-white">
                            {changeGenderName(j?.gender)}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="text-black dark:text-white">
                            <span
                              className="flex items-center justify-center mx-1"
                              title={t("Your rating")}
                            >
                              ({j?.rating?.toFixed(1)}
                              <AiTwotoneStar
                                className="inline ml-1 starRateInProfile"
                                fill="orange"
                              />
                              )
                            </span>
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark">
                          <p className="inline-flex m-1 rounded-full bg-success bg-opacity-10 py-1 px-3 text-sm font-medium text-success">
                            {order?.serviceName}
                          </p>
                        </td>
                        <td className="border-b border-[#eee] py-5 px-4 dark:border-strokedark ">
                          <div className="flex m-3 justify-between">
                            {selectedJumpers.includes(j?._id) ? (
                              <button
                                onClick={() => {
                                  undoAddJumpers(j?._id);
                                }}
                                className="inline-flex items-center justify-center bg-stroke rounded-full  px-2 text-danger py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90"
                              >
                                <BiUndo />
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setShowContractModal(true);
                                  setIdd(j?._id);
                                }}
                                className="inline-flex items-center justify-center text-success bg-success bg-opacity-50 rounded-full  px-2 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-30"
                              >
                                <FaCheck />
                              </button>
                            )}

                            <button className="inline-flex items-center justify-center rounded-md  px-3 py-1  m-1  text-center font-medium text-indigo hover:bg-opacity-90">
                              <Link
                                to={`/favdetails/${j?._id}`}
                                rel="noreferrer"
                              >
                                <svg
                                  className="fill-current"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M8.99981 14.8219C3.43106 14.8219 0.674805 9.50624 0.562305 9.28124C0.47793 9.11249 0.47793 8.88749 0.562305 8.71874C0.674805 8.49374 3.43106 3.20624 8.99981 3.20624C14.5686 3.20624 17.3248 8.49374 17.4373 8.71874C17.5217 8.88749 17.5217 9.11249 17.4373 9.28124C17.3248 9.50624 14.5686 14.8219 8.99981 14.8219ZM1.85605 8.99999C2.4748 10.0406 4.89356 13.5562 8.99981 13.5562C13.1061 13.5562 15.5248 10.0406 16.1436 8.99999C15.5248 7.95936 13.1061 4.44374 8.99981 4.44374C4.89356 4.44374 2.4748 7.95936 1.85605 8.99999Z"
                                    fill=""
                                  />
                                  <path
                                    d="M9 11.3906C7.67812 11.3906 6.60938 10.3219 6.60938 9C6.60938 7.67813 7.67812 6.60938 9 6.60938C10.3219 6.60938 11.3906 7.67813 11.3906 9C11.3906 10.3219 10.3219 11.3906 9 11.3906ZM9 7.875C8.38125 7.875 7.875 8.38125 7.875 9C7.875 9.61875 8.38125 10.125 9 10.125C9.61875 10.125 10.125 9.61875 10.125 9C10.125 8.38125 9.61875 7.875 9 7.875Z"
                                    fill=""
                                  />
                                </svg>
                              </Link>
                            </button>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <p className="text-5xl font-bold text-danger flex justify-center items-center animate-pulse">
          {notFound}
        </p>
      )}
    </>
  );
};

export default ClientSelectJumbers;
