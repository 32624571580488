import { Suspense, lazy, useEffect, useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import SignIn from "./pages/Authentication/SignIn";

import SignUp from "./pages/Authentication/SignUp";
import Loader from "./common/Loader";
import routes from "./routes";
import GeneralStatistics from "./pages/Dashboard/GeneralStatistics";
import OrdersStatistics from "./pages/Dashboard/OrdersStatistics";
import UpdateProfile from "./pages/AccountSettings/UpdateProfile";
import ChangePassword from "./pages/AccountSettings/ChangePassword";
import Invoice from "./pages/Invoice";
import AddNewService from "./pages/AddNewService";
import AddNewSkill from "./pages/AddNewSkill";
import NewOffers from "./pages/Offers/NewOffers";
import OfferDetails from "./pages/Offers/OfferDetails";
import AllOffers from "./pages/Offers/AllOffers";
import OrganizationInfo from "./pages/AccountSettings/OrganizationInfo";
import PaymentInfo from "./pages/Payment/PaymentInfo";
import PaymentSettings from "./pages/Payment/PaymentSettings";
import EditService from "./pages/EditService";
import ProtectedRoute from "./Protected/ProtectedRoute";
import ServiceDetails from "./pages/ServiceDetails";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import { fetchUser, setLoader } from "./Store/Actions/userActions";
import ClientGeneralStatistics from "./pages/ClientDashboard/ClientGeneralStatistics";
import ClientOrdersStatistics from "./pages/ClientDashboard/ClientOrdersStatistics";
import ClientUpdateProfile from "./pages/ClientAccountSettings/ClientUpdateProfile";
import ClientChangePassword from "./pages/ClientAccountSettings/ClientChangePassword";
import ClientOrganizationInfo from "./pages/ClientAccountSettings/ClientOrganizationInfo";
import ClientInvoice from "./pages/ClientInvoice";
import Contract from "./pages/contract/Contract";
import RequireOrderInfo from "./protectedRoutes/requireOrderInfo";
import RequireOrderTime from "./protectedRoutes/requireOrderTime";
import RequireOrderJumpers from "./protectedRoutes/requireOrderJumpers";
import ClientOrderInformation from "./pages/Orders/OrderInformation";
import ClientMyOrders from "./pages/Orders/MyOrders";
import ClientOrderDetails from "./pages/Orders/OrderDetais";
import ClientOrderTimingAndCosts from "./pages/Orders/OrderTimingAndCosting";
import ClientSelectJumbers from "./pages/Orders/SelectJumber";
import ClientReviewAndPublication from "./pages/Orders/ReviewAndPublication";
import "./App.css";
import { toast, ToastContainer } from "react-toastify";
import Orders from "./pages/Orders/AllOrders";
import Complain from "./pages/Orders/Complain";
import SignUpClient from "./pages/Authentication/SignUpClient";
import JumperProfile from "./pages/Orders/JumperProfile";
import JumperReview from "./pages/Orders/JumperReview";
import JumpersStatus from "./pages/Orders/JumpersStatus";
import PrivacyModal from "./components/PrivacyModal";
import AllInvoices from "./pages/Invoices/AllInvoices";
import InvoiceDetails from "./pages/Invoices/InvoiceDetails";
import io from "socket.io-client";
import PrivacyPolicy from "./pages/Authentication/PrivacyPolicy";
import Complaint from "./pages/Complaints/Complaint";
import ComplaintDetails from "./pages/Complaints/ComplaintDetails";
import ContractModal from "./components/ContractModal";
import SelectMore from "./pages/Orders/SelectMore";
import ContractModalClient from "./components/ContractModalClient";
import ContractClient from "./pages/contract/ContractClient";
import CompleteProfileClient from "./pages/CompleteProfileClient";
import { useTranslation } from "react-i18next";
import FavList from "./pages/FavList/FavList";
import FavDetails from "./pages/FavList/FavDetails";
import NotFound from "./pages/NotFound";
import PrivacyPolicyMobile from "./pages/Authentication/PrivacyPolicyMobile";
const ENDPOINT = "https://api.jumpersksa.online/";
let socket = io(ENDPOINT);
const DefaultLayout = lazy(() => import("./layout/DefaultLayout"));

function App() {
  const navigate = useNavigate();
  const { userInfo } = useSelector((state) => state.user);

  useEffect(() => {
    if (userInfo) {
      socket.emit("setup", userInfo._id);
      socket.on("connected", () => {
        console.log("socket connect");
      });
    }
  }, [userInfo]);

  const [loading, setLoading] = useState(true);

  const role = localStorage.getItem("role");
  const userData = JSON.parse(localStorage.getItem("userData"));

  const isRoleJumber = role === "jumper" ? true : false;
  const isRoleClient = role === "client" ? true : false;
  // const isRoleCompany =
  //   role === "client" && userData?.isCompany === true ? true : false;

  // console.log(`Role Here ${role}`);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1000);
  }, []);
  const dispatch = useDispatch();
  const [t, i18n] = useTranslation();
  // useEffect(() => {
  //   const token = localStorage.getItem("token");
  //   if (token) {
  //     dispatch(fetchUser(token, i18n.language));
  //   } else if (window.location.pathname === "/auth/privacypolicymobile") {
  //     navigate("/auth/privacypolicymobile"); 
  //   } else {
  //     dispatch(setLoader(false));
  //     navigate("/auth/signin");
  //   }
  // }, [dispatch, i18n.language, navigate]);
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      dispatch(fetchUser(token, i18n.language));
    } else {
      const protectedRoutes = [
        "/contracts",
        "/contractsofclient",
        "/",
        "/ordersstatistics",
        "/updateprofile",
        "/changepassword",
        "/invoice",
        "/addnewservice",
        "/addnewskill",
        "/editservice",
        "/servicedetails",
        "/newoffers",
        "/alloffers",
        "/offer",
        "/order",
        "/orders",
        "/paymentinfo",
        "/paymentsettings",
        "/organization",
        "/complaintDetails",
        "/completeProfileClient",
        "/complain",
        "/complaints",
        "/orderinfo",
        "/myorders",
        "/allinvoices",
        "/invoicedetails",
        "/privacymodal",
        "/contractModal",
        "/contractModalClient",
        "/selectmore",
        "/jumperreview",
        "/jumpersstatus",
        "/selectjumbers/jumperProfile",
        "/invoice",
        "/favlist",
        "/favdetails",
        "/ordertimingandcosts",
        "/selectjumbers",
        "/orderreviewandpublication",
      ];
      if (protectedRoutes.includes(window.location.pathname)) {
        navigate("/auth/signin");
      }
    }
  }, [dispatch, i18n.language, navigate]);


  return loading ? (
    <Loader />
  ) : (
    <div dir={i18n.language === "ar" && "rtl"}>
      <ToastContainer
        position={i18n.language === "ar" ? "top-left" : "top-right"}
      />
      <Routes>
        <Route path="/auth/signin" element={<SignIn />} />
        <Route path="/auth/signup" element={<SignUp />} />{" "}
        <Route path="/auth/signupclient" element={<SignUpClient />} />{" "}
        <Route path="/auth/privacypolicy" element={<PrivacyPolicy />} />{" "}
        <Route path="/auth/privacypolicymobile" element={<PrivacyPolicyMobile />} />{" "}
        <Route element={<DefaultLayout socket={socket} />}>
          <Route element={<ProtectedRoute />}>
            <Route path="/contracts/:id" element={<Contract />} />
            <Route path="/contractsofclient/:id" element={<ContractClient />} />
            {isRoleJumber && (
              <>
                <Route path="/" element={<GeneralStatistics />} />
                <Route
                  path="/ordersstatistics"
                  element={<OrdersStatistics />}
                />
                <Route path="/updateprofile" element={<UpdateProfile />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route path="/invoice" element={<Invoice />} />
                <Route path="/addnewservice" element={<AddNewService />} />{" "}
                <Route path="/addnewskill" element={<AddNewSkill />} />{" "}
                <Route path="/editservice/:id" element={<EditService />} />{" "}
                <Route
                  path="/servicedetails/:id"
                  element={<ServiceDetails />}
                />{" "}
                <Route path="/newoffers" element={<NewOffers />} />
                <Route
                  path="/alloffers"
                  element={<AllOffers socket={socket} />}
                />
                <Route
                  path="/offer/:id"
                  element={<OfferDetails socket={socket} />}
                />
                <Route
                  path="/order/:id"
                  element={<ClientOrderDetails socket={socket} />}
                />{" "}
                <Route path="/orders" element={<Orders socket={socket} />} />
                <Route path="/paymentinfo" element={<PaymentInfo />} />
                <Route path="/paymentinfo" element={<PaymentInfo />} />
                <Route path="/paymentsettings" element={<PaymentSettings />} />
              </>
            )}
            {routes.map(({ path, component: Component }) => (
              <Route
                path={path}
                element={
                  <Suspense fallback={<Loader />}>
                    <Component />
                  </Suspense>
                }
              />
            ))}
            {/* ************************************************** */}
            {isRoleClient && (
              <>
                <Route path="/" element={<GeneralStatistics />} />
                <Route
                  path="/ordersstatistics"
                  element={<OrdersStatistics />}
                />
                <Route path="/updateprofile" element={<UpdateProfile />} />
                <Route path="/changepassword" element={<ChangePassword />} />
                <Route
                  path="/complaintDetails/:id"
                  element={<ComplaintDetails />}
                />
                {/* <Route path="/organization" element={<OrganizationInfo />} /> */}
                <Route
                  path="/order/:id"
                  element={<ClientOrderDetails socket={socket} />}
                />{" "}
                <Route path="/orders" element={<Orders socket={socket} />} />
                <Route
                  path="/completeProfileClient"
                  element={<CompleteProfileClient />}
                />
                <Route path="/complain/:id" element={<Complain />} />
                <Route path="/complaints" element={<Complaint />} />
                <Route
                  path="/orderinfo"
                  element={<ClientOrderInformation />}
                />{" "}
                <Route path="/myorders" element={<ClientMyOrders />} />
                <Route path="/allinvoices" element={<AllInvoices />} />
                <Route
                  path="/invoicedetails/:id"
                  element={<InvoiceDetails />}
                />
                <Route path="/privacymodal" element={<PrivacyModal />} />
                <Route path="/contractModal" element={<ContractModal />} />
                <Route
                  path="/contractModalClient"
                  element={<ContractModalClient />}
                />
                <Route
                  path="/selectmore/:id"
                  element={<SelectMore socket={socket} />}
                />
                <Route path="/jumperreview/:id" element={<JumperReview />} />
                <Route
                  path="/jumpersstatus/:id"
                  element={<JumpersStatus socket={socket} />}
                />
                <Route
                  path="selectjumbers/jumperProfile/:id"
                  element={<JumperProfile />}
                />
                <Route path="/invoice" element={<ClientInvoice />} />
                <Route path="/favlist" element={<FavList />} />
                <Route path="/favdetails/:id" element={<FavDetails />} />
                <Route element={<RequireOrderInfo />}>
                  <Route
                    path="/ordertimingandcosts"
                    element={<ClientOrderTimingAndCosts />}
                  />
                  <Route element={<RequireOrderTime />}>
                    <Route
                      path="/selectjumbers"
                      element={<ClientSelectJumbers />}
                    />
                    <Route element={<RequireOrderTime />}>
                      <Route
                        path="/orderreviewandpublication"
                        element={<ClientReviewAndPublication socket={socket} />}
                      />
                    </Route>
                  </Route>
                </Route>
              </>
            )}
            {isRoleClient && userInfo?.isCompany && (
              <>
                <Route path="/organization" element={<OrganizationInfo />} />
              </>
            )}
          </Route>{" "}
        </Route>{" "}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </div>
  );
}

export default App;
